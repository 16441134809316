import React from "react";

import Banner from "../Home/Banner";
import Who from "../who we are/Who";

import Transport from "../Transport/Transport";
import About from "../About.us/About";
import Place from "../Important place/Place";
import Unesco from "../UNESCO/Unesco";
import Travel from "../Travel Banner/Travel";
import Help_you from "./We can help you with/Help_you";
import TruckService from "./TruckService";
import ReFund from "./ReFund";

const Home = () => {
  return (
    <div>
      <Banner />
      <Who />

      <Travel />
      <TruckService/>
      <Transport />
    
   

      <Place />
      <Unesco />
      <Help_you/>
      <About />
      <ReFund/>
    </div>
  );
};

export default Home;
