import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import About_image from "./ABOUT page Images/about.jpg";
import { TiTick } from "react-icons/ti";
import Tick from "./images/check.png";
import "./About.css";

const About = () => {
  return (
    <div className="mb-5">
      <div className="about_us_container_h1">
        <center>
          <h1 className="about_h1">About us</h1>
        </center>
      </div>
      <Container>
        <Row>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            // No need for order classes here
          >
            <div className="about_us_right_side_content_container p-5">
              <p className="Tourist p-2">ABOUT US</p>
              <h1 className="Welcome_to_Tourist_h1 p-2">
                We Provide Best Tour Packages In Your Budget
              </h1>
              <p className="about_us_para">
                Explore exotic destinations with our curated tour packages
                tailored to fit your budget perfectly. Experience unforgettable
                adventures without breaking the bank. Our team ensures every
                aspect of your journey is meticulously planned for maximum
                enjoyment. Trust us to deliver the best value for your money,
                making your travel dreams a reality. Start your next adventure
                with us today!
              </p>
              <p className="about_us_para">
                Embark on a journey of discovery with our unbeatable tour
                packages designed to cater to every traveler's budget. Whether
                you seek serene beach getaways or thrilling mountain
                expeditions, we have the perfect itinerary for you. Our
                commitment to affordability doesn't compromise on quality;
                expect top-notch accommodations, knowledgeable guides, and
                seamless logistics. Let us take care of the details while you
                focus on creating lasting memories. With our budget-friendly
                options, the world is within your reach.
              </p>
              <div className="div">
                <button className="login_button mt-3">Learn More </button>
              </div>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={12}
            sm={12}
            // No need for order classes here
          >
            <div className="image_container_in_about_us p-5">
              <img
                src={About_image}
                alt="about us juda kodaikanal tourism"
                className="side_image_in_about_us"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xxl={12} xl={12} lg={12} md={12} sm={12} xm={12}>
            <h3 className="Best_Travel_Agency_h3">
              Best Travel Agency In Kodaikanal
            </h3>
            <p className="about_us_para">
              Progressive Tours is a leading Kodaikanal based travel agency
              specialized in offering custom and pre-designed Kodiakanal
              destination tour packages. Providing our best services in tour and
              transport services since 2000, we are now one of the best inbound
              tour operators of Kodaikanal.
            </p>
            <p className="about_us_para">
              Kodaikanal is best destination for various tour segments as Luxury
              tour, Group tour, corporate tour, Leisure tour, Heritage tour,
              trekking tour and adventure tour. We facilitate our best services
              to travelers visiting Kodaikanal from the corners of the world.
              Our customer focus, innovative packages, brief of destination,
              personalized service, operational excellence and value oriented
              pricing keep us apart in this competitive sector
            </p>
            <p className="about_us_para">
              <strong className="strong">Our Vision : </strong>To achieve the name of top service
              provider with our transparency, authenticity and quality service
              in growing travel sector of Kodaikanal.
            </p>
            <p className="about_us_para">
              <strong className="strong">Our Mission:</strong>To come up with interesting tour
              packages that gives our valuable customers an offbeat and unique
              experience of travel in Kodaikanal.
            </p>
            <p className="about_us_para">
              Our team members are always dedicated to assist you with your
              queries about kodaikanal destination and packages. We take care of
              your tour with best quality of services and customization of
              travel packages. Our budget tour packages in kodaikanal will let
              you explore Kodaikanal with unlimited fun and fewer burdens on
              your travel budget.
            </p>
            <h4>WHY CHOOSE US?</h4>
            <p className="about_us_para">
              There are numerous travel agencies in Kodaikanal who promise to
              offer best services, so it’s a tough decision to choose the best
              one. You can trust as best travel agency in kodaikanal as we offer
              all types of customized tour across Kodaikanal . We are an
              authorized, recognized and experienced company providing our
              services all over the Kodaikanal. You can go through our customer
              reviews and ratings on search engines.
            </p>
            <br />
            <p>Here we are outlining some of the best reasons to choose us:</p>
            <ul style={{ listStyle: "none" }}>
              <li>
                <TiTick className="tick_about" />
                We are local trusted company with office based in Kodaikanal
              </li>
              <li>
                {" "}
                <TiTick className="tick_about" />
                For Unique Travel experiences
              </li>
              <li>
                {" "}
                <TiTick className="tick_about" />
                Personalized assistance on arrival & departure
              </li>
              <li>
                <TiTick className="tick_about" /> Niche wise travel packages
              </li>
              <li>
                <TiTick className="tick_about" /> Excellent guide and expert
                drivers
              </li>
              <li>
                <TiTick className="tick_about" /> Personalized and assured
                services
              </li>
              <li>
                <TiTick className="tick_about" /> Reasonable Pricing
              </li>
              <li>
                <TiTick className="tick_about" /> Excellent support and quick
                response
              </li>
              <li>
                <TiTick className="tick_about" /> Hassle free Travel
              </li>
              <li>
                <TiTick className="tick_about" /> Absolute transparency
              </li>
              <li>
                <TiTick className="tick_about" />
                Extensive fleet of vehicles including luxury cars, tempo
                travelers, large buses
              </li>
              <li>
                <TiTick className="tick_about" /> Sanitized and clean vehicles
                to prevent spread of Corona
              </li>
            </ul>{" "}
            <br />
            <h5>Responsible Travel Company</h5>
            <br />
            <p className="about_us_para">
              We are not just profit oriented travel partner, but we do care
              about environment, culture and social responsibility. As best
              transport service provider in Kodaikanal, We provide vehicles
              whose emissions are in line, permissible with Government
              guidelines. This helps in reduce carbon footprint from the
              environment and keep us and our visitors safe and healthy. Digital
              World is also very useful in reducing paper waste and protection
              of environment. So we prefer email communication, soft copy of
              documents for verification.
            </p>
            <br />
            <h5>Overview</h5>
            <p className="about_us_para">
              A four wheel drive through the hill roads has become one of the
              most adventurous entertainment in Kodaikanal. we provide with our
              tour packages an opportunity to discover the deep mountain paths
              to reach your destinations in the paradise through a well equipped
              jeep service. Guiding through your tours this jeep safari would
              take you down the road of adventure to absorb every inch of
              natural beauty of Kodaikanal.
            </p>
            <Row>
              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xm={12}>
                <div className="over_view_description">
                  <h3>
                    {" "}
                    <TiTick className="tick_about" />
                    Night Safari
                  </h3>
                  <p style={{ marginLeft: "30px" }} className="about_us_para">
                    Enjoy the True beauty of the wild. Night Safari , The Most
                    popular ‘Night Safari’ takes you to and enticing journey
                  </p>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xm={12}>
                <div className="over_view_description">
                  <h3>
                    {" "}
                    <TiTick className="tick_about" />
                    Local sight seening
                  </h3>
                  <p style={{ marginLeft: "30px" }} className="about_us_para">
                    Kodaikanal hills are situated at an altitude of 5000 to 8000
                    Ft above sea level. This beautiful hill station was once the
                    summer
                  </p>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xm={12}>
                <div className="over_view_description">
                  <h3>
                    {" "}
                    <TiTick className="tick_about" />
                    Off Road Safari
                  </h3>
                  <p style={{ marginLeft: "30px" }} className="about_us_para">
                    A four wheel drive through the hill roads has become one of
                    the most adventurous entertainment in munnar.
                  </p>
                </div>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xm={12}>
                <div className="over_view_description">
                  <h3>
                    {" "}
                    <TiTick className="tick_about" />
                    Student Packages
                  </h3>
                  <p style={{ marginLeft: "30px" }} className="about_us_para">
                    Student are situated at an altitude of 5000 to 8000 Ft above
                    sea level. This beautiful hill station was once the summer
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
