import Carousel from 'react-bootstrap/Carousel';
import  Observatory from './Carousel 3 Images/Observatory Pine Forest.webp';
import Rose from './Carousel 3 Images/Rose Garden.jpg';
import Palani from './Carousel 3 Images/Palani Temple View.webp';
import Mahalakshmi from './Carousel 3 Images/mahalakshmi-temple.jpg';
import Poombarai from './Carousel 3 Images/Poombarai Village View.jpg';
import Karthi from './Carousel 3 Images/Karthi Temple (3000 Years Old).jpg';
import Mannavanur from './Carousel 3 Images/Mannavanur Lake View.jpg';
import Sheep from './Carousel 3 Images/Sheep & Rabbit Form.jpg';
import Kodai from './Carousel 3 Images/Kodai Lake or City Drip.jpeg';




import './Carousel_3.css'
 
function DarkVariantExample() {
  return (
    <Carousel >
     <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Observatory }
          alt=" kodaikana Observatory Pine Forest l"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '> Pine Forest</p>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Rose }
          alt=" kodaikanal Rose Garden "
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '> Rose Garden</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Palani}
          alt=" kodaikanal Palani Temple View"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Palani Temple</p>
        </Carousel.Caption>
      </Carousel.Item>


      {/* Pambar Falls */}

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={ Mahalakshmi}
          alt="kodaikanal Mahalakshmi Temple"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Mahalakshmi Temple</p>
        </Carousel.Caption>
      </Carousel.Item>

      {/* Line Cave */}

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={ Poombarai }
          alt="kodaikanal Poombarai Village View"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Poombarai Village</p>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Karthi}
          alt="kodaikanal Karthi Temple (3000 Years Old)"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>  Karthi Temple</p>
        </Carousel.Caption>
      </Carousel.Item>



      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Mannavanur}
          alt="kodaikanal  Mannavanur Lake View"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>  Mannavanur Lake</p>
        </Carousel.Caption>
      </Carousel.Item>



      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Sheep}
          alt="kodaikanal Sheep & Rabbit Form"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Sheep Form</p>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Kodai}
          alt=" kodaikanal Kodai Lake or City Drip"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>  Kodai Lake</p>
        </Carousel.Caption>
      </Carousel.Item>




    </Carousel>




  );
}

export default DarkVariantExample;