import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Car_rental_kodaikanal from  '../images/kodaikanal car tab.jpeg'
import Food from '../images/lunch.jpeg'
import Sightseeing from '../images/kodaikanal sighning.jpeg'
import  Kodaikanal_tour_Packages from '../images/Kodaikanal tour Packages.jpeg'
import './Help_you.css'
import { Link } from 'react-router-dom';



const Help_you = () => {
  return (
    <div className='help_you_container_all p-1'>
      <Container className='mt-5 mb-5 help_you_container '>
<Row>
    <center>
        <h1 className='mb-4 mt-4'>We can help you with</h1>
    </center>
    {/* help_you_col_1 */}


    <Col xxl={3} xl={3} lg={3} md={6} sm={12}>
        <div className="help_you_col_1">
<img src={Car_rental_kodaikanal} alt="" srcset="Kodaikanal Car rental"  className='Kodaikanal_Car_rental_package_image' />

        </div><br />
        <h5>Kodaikanal Cab Services</h5>
<p className='help_you_p '>Pickip drop services from various cities.</p>
<br />
<Link to='all_packages' style={{textDecoration:'none'}}><p className='click_here p-3'>Click here</p></Link>

    </Col>

    <Col xxl={3} xl={3} lg={3} md={6} sm={12}>
        <div className="help_you_col_1">
<img src={Kodaikanal_tour_Packages} alt="" srcset="Kodaikanal Car rental"  className='Kodaikanal_Car_rental_package_image' />

        </div><br />
        <h5>Kodaikanal tour Packages</h5>
<p  className='help_you_p'>Offering various types of honeymoon tour packages</p>
<Link to='all_packages' style={{textDecoration:'none'}}><p className='click_here p-3'>Click here</p></Link>
    </Col>
   
    <Col xxl={3} xl={3} lg={3} md={6} sm={12}>
        <div className="help_you_col_1">
<img src={Sightseeing} alt="" srcset="Kodaikanal sightseeing"  className='Kodaikanal_Car_rental_package_image' />

        </div><br />
        <h5>Kodaikanal  sightseeing</h5>
<p  className='help_you_p'>Taxi for local sightseeing</p>
<br />
<Link to='all_packages' style={{textDecoration:'none'}}><p className='click_here p-3'>Click here</p></Link>
    </Col>

    <Col xxl={3} xl={3} lg={3} md={6} sm={12}>
        <div className="help_you_col_1">
<img src={ Food} alt="" srcset="Kodaikanal  Food"  className='Kodaikanal_Car_rental_package_image' />

        </div><br />
        <h5>Food and Accommodation</h5>
<p  className='help_you_p'>We offer both food and accommodation in our package.</p>
<Link to='all_packages' style={{textDecoration:'none'}}><p className='click_here p-3'>Click here</p></Link>
    </Col>
</Row>
      </Container>
    </div>
  )
}

export default Help_you
