import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ImInstagram } from "react-icons/im";
import { ImFacebook2 } from "react-icons/im";
import { BsWhatsapp } from "react-icons/bs";
import { FaXTwitter } from "react-icons/fa6";
import { GiMountaintop } from "react-icons/gi";
import { FaGooglePay } from "react-icons/fa6";
import { RiPaypalLine } from "react-icons/ri";
import { SiPaytm } from "react-icons/si";
import { FaCreditCard } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdPhonePortrait } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { TfiYoutube } from "react-icons/tfi";
import Card_1 from './Footer Credid card images/apple pay.png'
import Card_2 from './Footer Credid card images/gpay.jpeg'
import Card_3 from './Footer Credid card images/master card.png'
import Card_4 from './Footer Credid card images/pay pal.png'
import Card_5 from './Footer Credid card images/union pay.png'
import Card_6 from './Footer Credid card images/upi.png'
import Card_7 from './Footer Credid card images/visa.png'
import { FaWhatsappSquare } from "react-icons/fa";
import Qr from './Footer Credid card images/WhatsApp Image 2024-03-18 at 12.43.38_8fe6ddc4.jpg'
import { MdAddIcCall } from "react-icons/md";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer_container">
      <Container>
        <Row>
          {/* section 1 */}
          <Col xxl={3} xl={3} lg={6} md={6} sm={12} className="p-4">
            <h1 className="footer_col_1_h1 p-1">
              Kodaikanal <span>Tour Makers</span>
            </h1>
            <p className="footer_col_1_p p-1">
              Kodaikanal Tour Makers specializes in crafting tailor-made itineraries for
              personalized travel experiences, from relaxing beach getaways to
              adventurous mountain treks and immersive cultural journeys. With
              our dedicated team of experts, we're committed to exceeding your
              expectations and turning your travel dreams into unforgettable
              memories. Trust kodaikanal Tour Makers to guide you on your next
              extraordinary journey.
            </p>
            <hr />
            <div className="footer_social_medium_icon p-2">
             <a href="https://www.instagram.com/kodaikanal_tour_makers/?igsh=Y3QyMDRqaDI5M2x2"> <ImInstagram className="footer_icon p-2" /></a>
             <a href="https://www.facebook.com/profile.php?id=61557788096863"><ImFacebook2 className="footer_icon p-2" /></a> 
             <a href="https://www.youtube.com/@JudeTravels"><TfiYoutube  className="footer_icon p-2" /></a> 
             <a href="https://twitter.com/i/flow/login?redirect_after_login=%2FJude_Travels_"><FaXTwitter className="footer_icon p-2" /></a> 
            </div>
          </Col>

          {/* section 2 */}

          <Col xxl={3} xl={3} lg={6} md={6} sm={12} className="p-4">
            <h1 className="footer_col_1_h1 p-1">OUR SERVICES</h1>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              <p className="">
                <GiMountaintop className="footer_icon_1 " /> Home
              </p>
            </Link>
            <Link to="about" style={{ textDecoration: "none", color: "white" }}>
              <p className="">
                <GiMountaintop className="footer_icon_1 " /> About
              </p>
            </Link>
            <Link
              to="tour_packages"
              style={{ textDecoration: "none", color: "white" }}
            >
              <p className="">
                <GiMountaintop className="footer_icon_1 " /> Top Places
              </p>
            </Link>
            <Link
              to="jeep_safari"
              style={{ textDecoration: "none", color: "white" }}
            >
              <p className="jeep_safari">
                <GiMountaintop className="footer_icon_1 " />
                Jeep Safari
              </p>
            </Link>

            <Link
              to="our_transport"
              style={{ textDecoration: "none", color: "white" }}
            >
              <p className="">
                <GiMountaintop className="footer_icon_1 " />
                Our Transport
              </p>
            </Link>
            <Link
              to="all_packages"
              style={{ textDecoration: "none", color: "white" }}
            >
              <p className="">
                <GiMountaintop className="footer_icon_1 " />
                 Packages
              </p>
            </Link>

            <Link
              to="tariff"
              style={{ textDecoration: "none", color: "white" }}
            >
              <p className="">
                <GiMountaintop className="footer_icon_1 " />
                Tariff
              </p>
            </Link>
            <Link
              to="hotels"
              style={{ textDecoration: "none", color: "white" }}
            >
              <p className="">
                <GiMountaintop className="footer_icon_1 " />
                Hotels
              </p>
            </Link>
            <Link
              to="contact"
              style={{ textDecoration: "none", color: "white" }}
            >
              <p className="">
                <GiMountaintop className="footer_icon_1 " /> Contact Us
              </p>
            </Link>
          </Col>

          {/* section 3 */}

          <Col xxl={3} xl={3} lg={6} md={6} sm={12} className="p-4">
            <h1 className="footer_col_1_h1 p-1">Pay</h1>
            <p>We accepts the Following Payment Methods</p>
            <div className="money_card_container" style={{gap:'5px'}}>
            <p><img src={Card_7} alt="" className="footer_money_card_1 p-1 " /></p>
            <p><img src={Card_1} alt="" className="footer_money_card_1 " /></p>
            <p><img src={Card_2} alt="" className="footer_money_card_1 p-1 " /></p>
            <p><img src={Card_6} alt="" className="footer_money_card_1 " /></p>
            <p><img src={Card_3} alt="" className="footer_money_card_1  " /></p>
            <p><img src={Card_4} alt="" className="footer_money_card_1  " /></p>
           
            </div>
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12}  >
             <img src={Qr} alt="jude travels kodaikanal online payment " className="qr" />
              </Col>
            </Row>
          </Col>

          {/* section 4 */}

          <Col xxl={3} xl={3} lg={6} md={6} sm={12} className="p-4">
            <h1 className="footer_col_1_h1 p-1">CONTACT US</h1>
            <p className="p-1">
              <FaLocationDot className="footer_icon_1 "/>  
              17/304A,M M Street,Kodaikanal,
                Tamilnadu 624 101.


            </p>
            <p className="p-1 p_1_1">
              <IoMdPhonePortrait className="footer_icon_1 footer_icon_1_1 " />  +91 9363947383
            </p>
            <p className="p-1">
              <IoMdPhonePortrait className="footer_icon_1 " />  +91 9677304131
            </p>
            <p className="p-1">
              <IoMdPhonePortrait className="footer_icon_1 " />  +91 6369072019
            </p>

          
            <p className="p-1">
              <MdEmail className="footer_icon_1 " /> judetravelskodai@gmail.com
            </p>
          </Col>
          <hr />
          <center>
            <p>The copyright &copy;2024 Kodaikanal Tour Makers . All rights reserved  
                <a href="https://iqtechway.com/" target="_blank" style={{textDecoration:'none', color:'white', marginLeft:'3px'}}>Developed By Iqtech</a>
            </p>
          </center>
        </Row>
      </Container>
<div className="whatS_up">
  <a href="https://wa.me/+919363947383">
<FaWhatsappSquare  className="what_icon_fixed"/>
</a>


</div>
<div className="call">
<a href="tel:+919363947383">
  <MdAddIcCall className="call_icon_fixed p-1" />
</a>



</div>

    </div>
  );
};

export default Footer;
