import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextField from '@mui/material/TextField';
import Vector_image from '../Contact Images/contact us.jpeg'
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    arrivalDate: '',
    departureDate: '',
    destinationFrom: '',
    name: '',
    email: '',
    phone: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const generateWhatsAppLink = () => {
    const { arrivalDate,departureDate,destinationFrom, name, phone, email } = formData;
    const formattedMessage = encodeURIComponent(`Arrival Date :${arrivalDate}
    \nDeparture Date:${departureDate} 
     \nDestination:${destinationFrom} 
     \nName : ${name} \nPhone :  ${phone}\nEmail : ${email}`);
    const whatsappLink = `https://api.whatsapp.com/send?phone=+919363947383&text=${formattedMessage}`;
    window.open(whatsappLink, '_blank');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform validation
    const newErrors = {};
    // Add your validation logic here
    // For simplicity, let's assume all fields are required
    if (!formData.arrivalDate) {
      newErrors.arrivalDate = 'Arrival date is required';
    }
    if (!formData.departureDate) {
      newErrors.departureDate = 'Departure date is required';
    }
    if (!formData.destinationFrom) {
      newErrors.destinationFrom = 'Destination is required';
    }
    if (!formData.name) {
      newErrors.name = 'Name is required';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phone) {
      newErrors.phone = 'Phone is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number must be 10 digits';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      generateWhatsAppLink();
    }
  };

  return (
    <div className='mb-5'>
      <center>
        <div className="contact_us_h1_container">
          <h1 className='contact_us_h1'>Contact Us</h1>
        </div>
      </center>
      <Container className='mt-5'>
        <Row>
          <h1 className='mb-5 mt-5 fill_the_form'>Fill The Form</h1>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
            <form onSubmit={handleSubmit} className="needs-validation" noValidate>
              <TextField
                id="arrivalDate"
                name="arrivalDate"
                label="Select Arrival Date* :"
                type="date"
                variant="outlined"
                className="input_form mb-5"
                InputLabelProps={{ style: { fontSize: '20px' }, shrink: true }}
                value={formData.arrivalDate}
                onChange={handleChange}
                error={!!errors.arrivalDate}
                helperText={errors.arrivalDate}
              />

              <TextField
                id="departureDate"
                name="departureDate"
                label="Select Departure Date* :"
                type="date"
                variant="outlined"
                className="input_form mb-5"
                InputLabelProps={{ style: { fontSize: '20px' }, shrink: true }}
                value={formData.departureDate}
                onChange={handleChange}
                error={!!errors.departureDate}
                helperText={errors.departureDate}
              />

              <TextField
                id="destinationFrom"
                name="destinationFrom"
                label="Destination From* :"
                variant="outlined"
                className="input_form mb-5"
                InputLabelProps={{ style: { fontSize: '20px' } }}
                value={formData.destinationFrom}
                onChange={handleChange}
                error={!!errors.destinationFrom}
                helperText={errors.destinationFrom}
              />

              <TextField
                id="name"
                name="name"
                label="Name* :"
                variant="outlined"
                className="input_form mb-5"
                InputLabelProps={{ style: { fontSize: '20px' } }}
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />

              <TextField
                id="email"
                name="email"
                label="Email* :"
                variant="outlined"
                className="input_form mb-5"
                InputLabelProps={{ style: { fontSize: '20px' } }}
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />

              <TextField
                id="phone"
                name="phone"
                label="Phone* :"
                variant="outlined"
                className="input_form mb-5"
                InputLabelProps={{ style: { fontSize: '20px' } }}
                value={formData.phone}
                onChange={handleChange}
                error={!!errors.phone}
                helperText={errors.phone}
              />

              <button type="submit" className='login_button mt-3'>Submit</button>
            </form>
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
<img src={Vector_image} alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
