import React from "react";
import "./Unesco.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

const Unesco = () => {
  return (
    <div className="unesco_container">
      <Container fluid className="unesco_container-2 ">
        <Row className=" unesco_container_row">
          <Col className="unesco_wonders_col_1 mb-5">
            <h1 className="unesco_wonders_col_1_h1 mt-5 ">
              Explore The Spice Valley
            </h1>

            <p className="unesco_wonders_col_1_p p-3">
              Spice Valley Camp hosting eco-campers in South India is situated
              at about 5000 ft (1500 m) above sea level in the Western Ghats
              mountain range which are recognised by UNESCO as a World Heritage
              Site, one of the world’s eight ‘hottest hotspots’ of biological
              diversity, home to more than three hundred globally threatened
              species and subtropical ‘shola’ forests: often seen by guests
              around the Camp which covers about 200 acres, are elephants,
              Indian gaur, deer, colourful birds, and other wildlife.Spice
              Valley Camp accommodation is located 12 miles (20 km) from the
              hill station of Kodaikanal in Tamil Nadu, with around a 300 meters
              uphill trek from parking. Spice Valley adjoins Kodaikanal Wildlife
              sanctuary, far from built-up habitats with only the echoes of c.
              5000 yr-old dolmen circle belonging to a lost civilization whose
              remains survive amidst the beauty of Mother Nature.
            </p>
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};

export default Unesco;
