import { Container } from "@mui/material";
import React, { useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import car_1 from "./images/inside (1).jpg";
import car_2 from "./images/inside (2).jpg";
import car_3 from "./images/inside (3).jpg";
import bus_1 from "./images/mini couch (1).jpg";
import bus_2 from "./images/mini couch (2).jpg";
import bus_3 from "./images/mini couch 3.jpg";
import b_1 from "./images/BUS inside (1).jpg";
import b_2 from "./images/BUS inside (2).jpg";
import b_3 from "./images/19_seater_bus-hire-jaipur-seat.jpg";
import "./our_transport.css";
import { IoMdCheckmark } from "react-icons/io";
import Accordion from "react-bootstrap/Accordion";
import { BsJustify } from "react-icons/bs";

const Car_rental = () => {
  const [selectedKey, setSelectedKey] = useState(null);

  const handleSelect = (eventKey) => {
    setSelectedKey(eventKey === selectedKey ? null : eventKey);
  };

  return (
    <div className="mb-5">
      <div className="rental_car_banner">
        <center className="">
          <h1 className="rental_car_banner_h1">OUR TRANSPORT</h1>
        </center>
      </div>
      <Container>
        <Row className="mb-5">
          <Col>
            <div className="car_rental_description mt-5">
              <center className="mb-5">
                <p >
                  We provide the service rent a car in kodaikanal with driver
                </p>

                <p>
                  Our car rental company has been operating in India, offering a
                  wide range of cars for rent in Kodaikanal to suit your needs.
                  every need and budget. Our fleet includes everything from
                  compact hatchbacks and sedans to spacious SUVs and luxury
                  cars, all well-maintained and equipped with the latest
                  amenities.{" "}
                </p>

                <p>
                  We pride ourselves on providing exceptional customer service.
                  and flexible rental options, including daily, weekly, and
                  monthly rentals. Our team of dedicated professionals is always
                  ready to assist you with your car rental needs , whether
                  you’re going on a road trip or attending a special event.
                  event, or need a reliable mode of transportation for business.
                  or other personal use.
                </p>

                <p>
                  Our goal is to make your car rental experience seamless. and
                  enjoyable as possible, so you can focus on your travels. and
                  make the most of your time in India. Choose us for all your
                  car rental needs and experience the best in comfort,
                  convenience and value . Our one of the best service is innova
                  car rental in Madurai.
                </p>
              </center>
            </div>
          </Col>
        </Row>

        <Row className="mb-5">
          <center>
            <h1 className="mb-5">Car Rental</h1>
          </center>
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="p-5">
            <div className="transport_car">
              <div className="transport_car_img mb-3">
                <img
                  src={car_1}
                  alt="Car_rental"
                  className="transport_car_img_1 "
                />
              </div>
              <center>
                <h1 className="car_rental_h1">Car Rental</h1>
                <hr className="car_rental_hr" />
              </center>

              {/* Card Details start*/}
              <div className="transport_details p-2">
                <div className="transport_details_left_details p-1">
                  <li>
                    <IoMdCheckmark className="tick" />GPS Tracking
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    High Rated Driver
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Pushback Seat
                  </li>
                </div>

                <div className="transport_details_right_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    TV / Audio
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Full Ac
                  </li>
                </div>
              </div>
              {/* Card Details End*/}
              <center className="p-3"></center>
            </div>
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="p-5">
            <div className="transport_car">
              <div className="transport_car_img mb-3">
                <img
                  src={car_2}
                  alt="Car_rental"
                  className="transport_car_img_1"
                />
              </div>
              <center>
                <h1 className="car_rental_h1">Car Rental</h1>
                <hr className="car_rental_hr" />
              </center>

              {/* Card Details start*/}
              <div className="transport_details p-2">
                <div className="transport_details_left_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    GPS Tracking
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    High Rated Driver
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Pushback Seat
                  </li>
                </div>

                <div className="transport_details_right_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    TV / Audio
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Full Ac
                  </li>
                </div>
              </div>
              {/* Card Details End*/}
              <center className="p-3"></center>
            </div>
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="p-5">
            <div className="transport_car">
              <div className="transport_car_img mb-3">
                <img
                  src={car_3}
                  alt="Car_rental"
                  className="transport_car_img_1"
                />
              </div>
              <center>
                <h1 className="car_rental_h1">Car Rental</h1>
                <hr className="car_rental_hr" />
              </center>

              {/* Card Details start*/}
              <div className="transport_details p-2">
                <div className="transport_details_left_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    GPS Tracking
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    High Rated Driver
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Pushback Seat
                  </li>
                </div>

                <div className="transport_details_right_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    TV / Audio
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Full Ac
                  </li>
                </div>
              </div>
              {/* Card Details End*/}
              <center className="p-3"></center>
            </div>
          </Col>
        </Row>

        {/* Our Popular Vehicles Bus */}

        <Row className="mt-5">
          <center>
            <h1 className="mb-5">AC MINI COACH</h1>
          </center>
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="p-5">
            <div className="transport_car">
              <div className="transport_car_img mb-3">
                <img
                  src={bus_1}
                  alt="Car_rental"
                  className="transport_car_img_1"
                />
              </div>
              <center>
                <h1 className="car_rental_h1">AC MINI COACH</h1>
                <hr className="car_rental_hr" />
              </center>

              {/* Card Details start*/}
              <div className="transport_details p-2">
                <div className="transport_details_left_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    GPS Tracking
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    High Rated Driver
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Pushback Seat
                  </li>
                </div>

                <div className="transport_details_right_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    TV / Audio
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Full Ac
                  </li>
                </div>
              </div>
              {/* Card Details End*/}
              <center className="p-3"></center>
            </div>
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="p-5">
            <div className="transport_car">
              <div className="transport_car_img mb-3">
                <img
                  src={bus_2}
                  alt="Car_rental"
                  className="transport_car_img_1"
                />
              </div>
              <center>
                <h1 className="car_rental_h1">AC MINI COACH</h1>
                <hr className="car_rental_hr" />
              </center>

              {/* Card Details start*/}
              <div className="transport_details p-2">
                <div className="transport_details_left_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    GPS Tracking
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    High Rated Driver
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Pushback Seat
                  </li>
                </div>

                <div className="transport_details_right_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    TV / Audio
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Full Ac
                  </li>
                </div>
              </div>
              {/* Card Details End*/}
              <center className="p-3"></center>
            </div>
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="p-5">
            <div className="transport_car">
              <div className="transport_car_img mb-3">
                <img
                  src={bus_3}
                  alt="Car_rental"
                  className="transport_car_img_1"
                />
              </div>
              <center>
                <h1 className="car_rental_h1">AC MINI COACH</h1>
                <hr className="car_rental_hr" />
              </center>

              {/* Card Details start*/}
              <div className="transport_details p-2">
                <div className="transport_details_left_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    GPS Tracking
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    High Rated Driver
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Pushback Seat
                  </li>
                </div>

                <div className="transport_details_right_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    TV / Audio
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Full Ac
                  </li>
                </div>
              </div>
              {/* Card Details End*/}
              <center className="p-3"></center>
            </div>
          </Col>
        </Row>

        {/* Our Popular Bus */}

        <Row className="mt-5">
          <center>
            <h1 className="mb-5">OUR POPULAR LUXURY COACH </h1>
          </center>
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="p-5">
            <div className="transport_car">
              <div className="transport_car_img mb-3">
                <img
                  src={b_1}
                  alt="Car_rental"
                  className="transport_car_img_1"
                />
              </div>
              <center>
                <h1 className="car_rental_h1">LUXURY COACH</h1>
                <hr className="car_rental_hr" />
              </center>

              {/* Card Details start*/}
              <div className="transport_details p-2">
                <div className="transport_details_left_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    GPS Tracking
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    High Rated Driver
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Pushback Seat
                  </li>
                </div>

                <div className="transport_details_right_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    TV / Audio
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Full Ac
                  </li>
                </div>
              </div>
              {/* Card Details End*/}
              <center className="p-3"></center>
            </div>
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="p-5">
            <div className="transport_car">
              <div className="transport_car_img mb-3">
                <img
                  src={b_2}
                  alt="Car_rental"
                  className="transport_car_img_1"
                />
              </div>
              <center>
                <h1 className="car_rental_h1">LUXURY COACH</h1>
                <hr className="car_rental_hr" />
              </center>

              {/* Card Details start*/}
              <div className="transport_details p-2">
                <div className="transport_details_left_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    GPS Tracking
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    High Rated Driver
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Pushback Seat
                  </li>
                </div>

                <div className="transport_details_right_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    TV / Audio
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Full Ac
                  </li>
                </div>
              </div>
              {/* Card Details End*/}
              <center className="p-3"></center>
            </div>
          </Col>

           <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="p-5">
            <div className="transport_car">
              <div className="transport_car_img mb-3">
                <img
                  src={b_3}
                  alt="Car_rental"
                  className="transport_car_img_1"
                />
              </div>
              <center>
                <h1 className="car_rental_h1">LUXURY COACH</h1>
                <hr className="car_rental_hr" />
              </center>

              {/* Card Details start*/}
              <div className="transport_details p-2">
                <div className="transport_details_left_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    GPS Tracking
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    High Rated Driver
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Pushback Seat
                  </li>
                </div>

                <div className="transport_details_right_details">
                  <li>
                    <IoMdCheckmark className="tick" />
                    TV / Audio
                  </li>
                  <li>
                    <IoMdCheckmark className="tick" />
                    Full Ac
                  </li>
                </div>
              </div>
              {/* Card Details End*/}
              <center className="p-3"></center>
            </div>
          </Col>
        </Row>

        {/* section 2 */}
        <Row className="mt-5">
          <center className="faq p-5">FAQ</center>

          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Is Traveler is safe for night journey?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, You can use in night journey. <br />
                  <br />
                  How much average time taken by Tempo Traveler from Madurai to
                  rameshwaram? <br />
                  <br />
                  Its takes 3 to 4 hours
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <div>
            <br />
          </div>

          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What does your price include?
                </Accordion.Header>
                <Accordion.Body>
                  Our price includes practically everything i.e. Driver (with
                  his boarding and lodging), fuel, parking, insurance and all
                  taxes unless specified otherwise. You don’t have to pay
                  anything extra for hiring the Traveler.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <div>
            <br />
          </div>

          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How do you calculate mileage and no. of days?
                </Accordion.Header>
                <Accordion.Body>
                  The mileage and No. of days are calculated from our garage to
                  garage in city. In case you leave the Traveler at any other
                  place than from where you have rented then No. of days and
                  mileage will be calculated up to our office in city where you
                  rent it from.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <div>
            <br />
          </div>

          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What are your payment terms ?
                </Accordion.Header>
                <Accordion.Body>
                  You can pay us in advance through Bank remittance HDFC and
                  Kotak Mahindra Bank. In certain cases we may ask you to pay us
                  in advance as a condition. In due course of time, we shall
                  also be accepting secured online credit card payments.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <div>
            <br />
          </div>

          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What happens if I have to cancel or change my reservation?
                </Accordion.Header>
                <Accordion.Body>
                  Normally, there is no problem and there is no penalty for
                  that. But, in certain circumstances where we ask for advance
                  payment as a condition, it may levy cancellation or amendment
                  charges which we shall let you know at the time you make your
                  booking.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <div>
            <br />
          </div>

          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What will happen if I have to change my tour programme during
                  the trip?
                </Accordion.Header>
                <Accordion.Body>
                  This is possible and in case of longer duration, we shall work
                  out additional charges and let you know at the time when you
                  make changes and in cases of lesser number of days we shall
                  let you know the refund amount due to you which will be paid
                  to you. booking.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <div>
            <br />
          </div>

          <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Is the boarding and lodging of driver included in the price
                  and will he be at our disposal all the time?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, the boarding and lodging of driver is included in the
                  price but they normally expect some tips after they have
                  provided you satisfactory service which is of course not
                  obligatory. Normally, our driver will be at disposal you can
                  start your trip at any time in the morning and even use till
                  late evening. For example if you occasionally use him from 6
                  AM to 10 PM, it is o.k. if it is not every day.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <div>
            <br />
          </div>

        </Row>
      </Container>
    </div>
  );
};

export default Car_rental;
