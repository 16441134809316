import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReFund_img_png from './Refund Images/Return-and-Cancellation-Policy-Template-for-E-Commerce-Website-828079_big-removebg-preview.png';
import './ReFund.css'

const ReFund = () => {
  return (
    <div className="mt-5 mb-5 refund_container_div">
      <Container>
        <center>
          <h1 className="refund_h1">
          Kodaikanal Tour Makers Satisfaction Guarantee: Your Assurance of Quality Service and <span className="refund_h1_span"> #20% Refund Policy
            Service
            </span>
          </h1> <br />
          <p  className="refund_para">
            At Kodaikanal Tour Makers, we're committed to providing you with exceptional
            service that exceeds your expectations. We understand that your
            travel experiences matter, and if for any reason our service doesn't
            meet your satisfaction, we offer a 20% refund on your money as part
            of our Satisfaction Guarantee.
          </p>
        </center> 
        <Row className="mt-2 justify-content-center p-3">
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
            <p  className="refund_para">
              <strong className="refund_strong">20% Refund Policy: </strong>As a demonstration of our
              commitment to your satisfaction, we offer a 20% refund on your
              money if you're dissatisfied with our service. This refund
              reflects our acknowledgment of any inconvenience caused and our
              determination to make things right.
            </p>

            <p  className="refund_para">
              <strong className="refund_strong">Quality Service Assurance: </strong> Our team is dedicated
              to ensuring that every aspect of your travel experience is of the
              highest quality. From booking your trip to your return home, we
              strive for excellence in every detail.
            </p>
            <p  className="refund_para">
              <strong className="refund_strong">Feedback Mechanism: </strong> We value your feedback. If
              there's any aspect of our service that falls short of your
              expectations, please let us know. Your feedback helps us
              continuously improve and enhance our offerings.
            </p>
            <p  className="refund_para">
              <strong className="refund_strong">Prompt Resolution: </strong>In the rare event that you're
              not satisfied with our service, we aim to resolve the issue
              promptly and to your satisfaction. Our customer support team is
              available to address any concerns you may have.
            </p>
            <p className="refund_para">
              <strong className="refund_strong">Easy Refund Process: </strong>Requesting a refund is
              simple. Just reach out to our customer support team with details
              of your experience, and we'll process your refund promptly and
              courteously.
            </p>
            
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12} xm={12}>
            <img src={ReFund_img_png} alt="jude travels refund methode" className="refund_image" />
          </Col>
        </Row> <br />
        <center>
        
            <p  >
              At Kodaikanal Tour Makers, we believe in going the extra mile to ensure that
              your travel experience is enjoyable, stress-free, and memorable.
              Our Satisfaction Guarantee is our promise to you that we stand
              behind the quality of our service and are committed to your
              satisfaction.
            </p> <br />
          <p >
            Travel with confidence with Kodaikanal Tour Makers, where your satisfaction is
            our priority.
          </p>
        </center>
      </Container>
    </div>
  );
};

export default ReFund;
