

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Seden from './Images/Seden  3.jpg'
import Hatcheback from './Images/Suzuki Cultus Celerio Wagon R Latest Model _ Rent a Car Lahore.jpeg'
import SUV from './Images/innova 33.webp'
import Tempo from './Images/Tempo Traveller.jpeg'
import {Link } from "react-router-dom";
import './Transport.css'

const Transport = () => {
  return (
    <div className='offer_container'>
      <Container fluid className='p-5'>
        <Row className='mb-5'>
          <Col xxl={4} xl={4} lg={4} md={12} sm={12} className=' mb-3'>
            <div className="your_cars_heading_container">
            <h6 style={{color:'white'}}>CAR CLASSES AND RATES</h6>
            <h1 style={{color:'white'}}>Choose Your Car</h1>
            <p className='taxi_offer'>Our Taxi service offers a big cars selection from  Luxury vechicles to budget Cars</p>
           
            </div>
            <br/>
            <Link to='our_transport' style={{textDecoration:'none'}}> <p className='transport_know_more p-3'>Know More</p></Link>
           
          </Col>

          <Col xxl={4} xl={4} lg={6} md={12} sm={12} className="seden_cars_col mb-3">
      <div className="seden_cars_col_2">
        <img src={Seden} alt="kodaikanal seden car offers" className='seden_car_image  p-1' />
      </div>
      <h3 className='seden_car_h3 p-2' style={{color:'white'}}>Seden Car</h3>
     
          </Col>

          <Col xxl={4} xl={4} lg={6} md={12} sm={12} className="seden_cars_col mb-3">
      <div className="seden_cars_col_2">
        <img src={Hatcheback} alt="kodaikanal seden car offers" className='seden_car_image p-1' />
      </div>
      <h3 className='seden_car_h3 p-2'>Hatcheback</h3>
      
          </Col>
        </Row>


        <Row>
          
          <Col xxl={4} xl={6} lg={6} md={12} sm={12} className="seden_cars_col mb-3">
      <div className="seden_cars_col_2">
        <img src={SUV} alt="kodaikanal seden car offers" className='seden_car_image  p-1' />
      </div>
      <h3 className='seden_car_h3 p-2'>SUV</h3>
      
          </Col>

          <Col xxl={4} xl={4} lg={4} md={12} sm={12} className="seden_cars_col mb-3">
      <div className="seden_cars_col_2">
        <img src={Tempo} alt="kodaikanal seden car offers" className='seden_car_image  p-1' />
      </div>
      <h3 className='seden_car_h3 p-2'>Tempo Traveller</h3>
      
          </Col>

          <Col xxl={4} xl={4} lg={6} md={12} sm={12} className=' mb-3'>
            <div className="your_cars_heading_container">
            <h3 style={{color:'white'}}>Find All</h3>
            <h3 style={{color:'white'}}>Destination</h3>
          
            <p className='taxi_offer'>Our Taxi service offers a big cars selection from  Luxury vechicles to budget Cars</p>
            </div>
            <br/>
            <Link to='our_transport' style={{textDecoration:'none'}}> <p className='transport_know_more p-3'>Know More</p></Link>
          </Col>

        </Row>
      </Container>
    </div>
  )
}

export default Transport
