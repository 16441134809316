import React from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Tarrif.css';

const Tarrif = () => {
  return (
    <div>
     <div className="tariff_banner">
        <h1 className='tariff_h1'>Tariff</h1>
        </div>
        <Container className='mt-5 mb-5 custom-table-scroll' >
            <Row className='justify-content-center text-center'>
                <h3 className='mb-4'>
                PICNIC TOUR (WITH TREKKING)-AFFORDABLE RATE
                </h3>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
                    <div className="table-responsive">
                <Table striped bordered hover size="sm" className='custom-table-scroll'>
             
      <thead>
        <tr>
        
          <th className='th' colSpan={8} scope="col">PICNIC TOUR (WITH TREKKING)-AFFORDABLE RATE</th>
        </tr>
      </thead>
      <tbody>
        <tr >
          <td className='td' >VEHICLE</td>
          <td className='td'>INDICA</td>
          <td className='td'>SEDAN</td>
          <td className='td'>TAVERA</td>
           <td className='td'>INNOVA</td>
          <td className='td'>TEMPO</td>
          <td className='td'>MINI BUS</td>
        
        </tr>
        <tr>
          <th className='td' scope="row">OFF-SEASON TARIFF</th>
          <td>Rs.1500</td>
          <td>Rs.1700</td>
          <td>Rs.1999</td>
          <td>Rs.2299</td>
          <td>Rs.2999</td>
          <td>Rs.3600</td>
        </tr>
        <tr>
          <th className='td' scope="row">SEASON TARIFF</th>
          <td>Rs.1999</td>
          <td>Rs.2299</td>
          <td>Rs.2499</td>
          <td>Rs.3000</td>
          <td>Rs.3999</td>
          <td>Rs.4600</td>
        </tr>
      </tbody>
    </Table>
    </div>        
                </Col>
            </Row>
        </Container>

        <div>
            <br />
            <br />
        
        </div>

        {/* BERIJAM FOREST TOURS (PERMISSION EXTRA) AFFORDABLE */}

        <Container className='mt-5 mb-5 custom-table-scroll'>
            <Row className='justify-content-center text-center'>
                <h3 className='mb-4'>
                BERIJAM FOREST TOURS (PERMISSION EXTRA) AFFORDABLE
                </h3>
                <Col>
                <Table striped bordered hover size="sm" className='custom-table-scroll'>
      <thead>
        <tr>
        
          <th className='th' colSpan={8}> BERIJAM FOREST TOURS (PERMISSION EXTRA) AFFORDABLE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td className='td'>VEHICLE</td>
          <td className='td'>INDICA</td>
          <td className='td'>SEDAN</td>
          <td className='td'>TAVERA</td>
           <td className='td'>INNOVA</td>
          <td className='td'>TEMPO</td>
          <td className='td'>MINI BUS</td>
        
        </tr>
        <tr>
          <td className='td'>OFF-SEASON TARIFF</td>
          <td>Rs.1800</td>
          <td>Rs.2000</td>
          <td>Rs.2400</td>
          <td>Rs.2600</td>
          <td>Rs.3600</td>
          <td>Rs.4000</td>
        </tr>
        <tr>
          <td className='td'>SEASON TARIFF</td>
          <td>Rs.2300</td>
          <td>Rs.2600</td>
          <td>Rs.2800</td>
          <td>Rs.3400</td>
          <td>Rs.4000</td>
          <td>Rs.4500</td>
        </tr>
      </tbody>
    </Table>
                
                </Col>
            </Row>
        </Container>

        <div>
            <br />
            <br />
      
        </div>
        {/* VILLAGE TOUR UPTO MANNAVANOOR-(4hrs trip)-AFFORDABLE RATE */}
            
        <Container className='mt-5 mb-5 custom-table-scroll'>
            <Row className='justify-content-center text-center'>
                <h3 className='mb-4'>
            VILLAGE TOUR UPTO MANNAVANOOR-(4hrs trip)-AFFORDABLE RATE
                </h3>
                <Col>
                <Table striped bordered hover size="sm">
      <thead>
        <tr>
        
          <th className='th'  colSpan={8}>   VILLAGE TOUR UPTO MANNAVANOOR-(4hrs trip)-AFFORDABLE RATE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <td className='td'>VEHICLE</td>
          <td className='td'>INDICA</td>
          <td className='td'>SEDAN</td>
          <td className='td'>TAVERA</td>
           <td className='td'>INNOVA</td>
          <td className='td'>TEMPO</td>
          <td className='td'>MINI BUS</td>
        
        </tr>
        <tr>
          <td className='td'>OFF-SEASON TARIFF</td>
          <td >Rs.1999</td>
          <td>Rs.2299</td>
          <td>Rs.2600</td>
          <td>Rs.2800</td>
          <td>Rs.3600</td>
          <td>Rs.4000</td>
        </tr>
        <tr>
          <td className='td'>SEASON TARIFF</td>
          <td>Rs.2500</td>
          <td>Rs.2600</td>
          <td>Rs.3000</td>
          <td>Rs.3500</td>
          <td>Rs.4500</td>
          <td>Rs.4600</td>
        </tr>
      </tbody>
    </Table>
                
                </Col>
            </Row>
        </Container>


        <div>
            <br />
            <br />
     
        </div>

        <Container className='mt-5 mb-5 custom-table-scroll'>
            <Row className='justify-content-center text-center'>
                <h3 className='mb-4'>
           VALLEY TOUR
                </h3>
                <Col>
                <Table striped bordered hover size="sm">
      <thead>
        <tr>
        
          <th className='th' colSpan={8}>      VALLEY TOUR  AFFORDABLE RATE</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='td'>VEHICLE</td>
          <td className='td'>INDICA</td>
          <td className='td'>SEDAN</td>
          <td className='td'>TAVERA</td>
           <td className='td'>INNOVA</td>
          <td className='td'>TEMPO</td>
          <td className='td'>MINI BUS</td>
        
        </tr>
        <tr>
          <td className='td'>OFF-SEASON TARIFF</td>
          <td>Rs.1500</td>
          <td>Rs.1700</td>
          <td>Rs.1999</td>
          <td>Rs.2299</td>
          <td>Rs.3299</td>
          <td>Rs.3600</td>
        </tr>
        <tr>
          <td className='td'>SEASON TARIFF</td>
          <td>Rs.1999</td>
          <td>Rs.2299</td>
          <td>Rs.2499</td>
          <td>Rs.3000</td>
          <td>Rs.3900</td>
          <td>Rs.4600</td>
        </tr>
      </tbody>
    </Table>
                
                </Col>
            </Row>
        </Container>

        <div>
            <br />
            <br />
    
        </div>

{/* Destination */}

        <Container className='mt-5 mb-5 custom-table-scroll  custom-table-scroll_big'>
            <Row className='justify-content-center text-center'>
                <h3 className='mb-4'>
                Destination
                </h3>
                <Col>
                <Table striped bordered hover size="sm">
      <thead>
        <tr>
        
          <th className='th' colSpan={9}> Your Destination From And To</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='td'>DESTINATION</td>
          <td className='td'>FROM</td>
          <td className='td'>DISTANCE</td>
          <td className='td'>INDICA 4+1</td>
           <td className='td'>SEDAN 4+1</td>
          <td className='td'>TAVERA 8+1</td>
          <td className='td'>INNOVA 6+1</td>
          <td className='td'>TEMPO 12 +1</td>
          <td className='td'>21 SEATER COACH</td>
        
        </tr>
        <tr>
          <td className='td'>KODAI ROAD</td>
          <td>Kodaikanal</td>
          <td>80 KM</td>
          <td>Rs.2299</td>
          <td>Rs.2499</td>
          <td>Rs.3000</td>
          <td>Rs.3500</td>
          <td>Rs.5000</td>
          <td>Rs.6000</td>
        </tr>
        <tr>
          <td className='td'>DINDIGUL</td>
          <td>Kodaikanal</td>
          <td>98 KM</td>
          <td>Rs.2500</td>
          <td>Rs.2800</td>
          <td>Rs.3500</td>
          <td>Rs.3800</td>
          <td>Rs.6000</td>
          <td>Rs.7000</td>
        </tr>

        <tr>
          <td className='td'>MADURAI</td>
          <td>Kodaikanal</td>
          <td>124 KM</td>
          <td>Rs.3600</td>
          <td>Rs.4000</td>
          <td>Rs.4500</td>
          <td>Rs.5000</td>
          <td>Rs.7500</td>
          <td>Rs.9000</td>
        </tr>

        <tr>
          <td className='td'>PALANI</td>
          <td>Kodaikanal</td>
          <td>65 KM</td>
          <td>Rs.2299</td>
          <td>Rs.2499</td>
          <td>Rs.3000</td>
          <td>Rs.3500</td>
          <td>Rs.4000</td>
          <td>Rs.5000</td>
        </tr>


        <tr>
          <td className='td'>COIMBATORE</td>
          <td>Kodaikanal</td>
          <td>183 KM</td>
          <td>Rs.5000</td>
          <td>Rs.6000</td>
          <td>Rs.7000</td>
          <td>Rs.8000</td>
          <td>Rs.9500</td>
          <td>Rs.12,500</td>
        </tr>

        <tr>
          <td className='td'>OOTY</td>
          <td>Kodaikanal</td>
          <td>280 KM</td>
          <td>Rs.7000</td>
          <td>Rs.8000</td>
          <td>Rs.9000</td>
          <td>Rs.9900</td>
          <td>Rs.14,500</td>
          <td>Rs.18,000</td>
        </tr>

        <tr>
          <td className='td'>THEKKADY</td>
          <td>Kodaikanal</td>
          <td>150 KM</td>
          <td>Rs.4500</td>
          <td>Rs.5000</td>
          <td>Rs.6000</td>
          <td>Rs.7000</td>
          <td>Rs.8000</td>
          <td>Rs.10,000</td>
        </tr>

        
        <tr>
          <td className='td'>MUNNAR</td>
          <td>Kodaikanal</td>
          <td>180 KM</td>
          <td>Rs.5500</td>
          <td>Rs.6000</td>
          <td>Rs.7500</td>
          <td>Rs.8500</td>
          <td>Rs.10,500</td>
          <td>Rs.12,500</td>
        </tr>

        <tr>
          <td className='td'>RAMESHWARAM</td>
          <td>Kodaikanal</td>
          <td>295 KM</td>
          <td>Rs.7000</td>
          <td>Rs.8500</td>
          <td>Rs.11,000</td>
          <td>Rs.12,000</td>
          <td>Rs.16,000</td>
          <td>Rs.19,000</td>
        </tr>

        <tr>
          <td className='td'>TRICHY</td>
          <td>Kodaikanal</td>
          <td>199 KM</td>
          <td>Rs.6000</td>
          <td>Rs.7000</td>
          <td>Rs.8000</td>
          <td>Rs.9000</td>
          <td>Rs.11,000</td>
          <td>Rs.15,000</td>
        </tr>
      </tbody>
    </Table>
                
                </Col>
            </Row>
        </Container>
        <div>
            <br />
            <br />
            <br />
        </div>
    </div>
  )
}

export default Tarrif

