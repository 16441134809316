import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ImInstagram } from "react-icons/im";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

import './Banner_header.css'

const Banner_header = () => {
  return (
    <div>
      <Container fluid className='Banner_header_header'> 
        <Row>
<Col className='follow_us_section'>
<div className="follow_us">
    <p className="follow_us_p" >Follow us</p>
 <i className="follow_us_i"><ImInstagram /></i>   
 <i className="follow_us_i"><FaFacebookF /></i>   
 <i className="follow_us_i"><FaXTwitter /></i>   
 <i className="follow_us_i" ><FaWhatsapp /></i>   
 



</div>
</Col>



<Col  className='login_button_col'>
    <div>
    <button className='login_button'><FaPhoneAlt /></button>
    </div>

</Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner_header
