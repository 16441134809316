import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Guna from "./Tour Pakages Images/Guna Cave.jpg";
import Coakers from "./Tour Pakages Images/Coakers Walk.jpg";
import Kodaikanal from "./Tour Pakages Images/the-misty-lake.jpg";
import Berijam from "./Tour Pakages Images/berijam-lake.jpg";
import Pine from "./Tour Pakages Images/Pine Forest, Kodaikanal.webp";
import Pillar from "./Tour Pakages Images/Pillar Rocks.jpg";
import Poombarai from "./Tour Pakages Images/Poombarai Village View.jpg";
import Kurinji from "./Tour Pakages Images/kurinji-andavar-temple.jpg";
import Mannavanur from "./Tour Pakages Images/Mannavanur Lake.jpg";
import Dolphin from "./Tour Pakages Images/dolphin-s-nose.jpg";
import Vattakanal from "./Tour Pakages Images/Vattakanal Waterfalls.jpg";
import Silent from "./Tour Pakages Images/Silent Valley View Point.jpg";
import Shembaganur from "./Tour Pakages Images/Shembaganur Museum of Natural History.jpg";
import Lake from "./Tour Pakages Images/Lake View Point.jpg";
import Chettiar from "./Tour Pakages Images/Chettiar Park.jpg";
import Perumal from "./Tour Pakages Images/perumal-peak.jpg";
import Kukkal from "./Tour Pakages Images/kukkal-caves.jpg";
import Solar from "./Tour Pakages Images/solar-physical-observatory.jpg";
import Bear from "./Tour Pakages Images/Bear Shola Falls.jpg";
import Christ from "./Tour Pakages Images/christ-the-king-church.jpg";
import "./Tour_packages.css";

const Service = () => {
  return (
    <div>
      <div className="tour_package_banner mb-5">
        <center>
          <h1 className="tour_packahe_h1">Tour Packages</h1>
        </center>
      </div>
      <Container>
        <Row className="mb-5">
          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Guna}
                alt="Guna cave Kodikanal"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Guna cave</p>
            </div>
            <br />
            <h6 className="">Guna cave</h6>
            <p className="package_list_card_p">
              It is about a unique natural heritage site called the Devil's
              Kitchen or Guna Caves. It is located at the outskirts of
              Kodaikanal town and it can be reached from the popular Moir Point.
              The place, which remained little known and was rarely visited only
              by the hikers became very popular after a Tamil movie named 'Guna'
              was shot here in 1992.
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Coakers}
                alt=" Kodikanal Coakers Walk"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Coakers Walk</p>
            </div>
            <br />
            <h6> Coakers Walk</h6>
            <p className="package_list_card_p">
              Coakers Walk is a beautiful man-made one-kilometre-long walking
              plaza built on the mountains in the mesmerizing city of
              Kodaikanal. The lovely path of Coakers Walk that curves by the
              edge of the gorgeous mountain have been constructed mainly for
              morning and evening walks. One can also choose to ride a bicycle
              to explore the scenic location.
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Kodaikanal}
                alt="Kodaikanal Lake Kodikanal"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Kodaikanal Lake</p>
            </div> <br />
            <h6>Kodaikanal Lake</h6>
            <p className="package_list_card_p">
              Kodaikanal Lake is a manmade lake in the Kodaikanal city which is
              also known as Kodai Lake. Vera Levinge was the man responsible for
              the creativity and resources of this lake amidst Kodaikanal town.
              This lake was developed by the British and early missionaries from
              the USASA.
            </p>
          </Col>

            <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container" >
              <img src={Berijam} alt="Berijam Lake Kodikanal" className='package_list_card_image'  />
              <p className='package_list_card_image_p'>Berijam Lake</p>
            </div> <br />
            <h6>Berijam Lake</h6>
            <p className="package_list_card_p">Berijam Lake is a freshwater reservoir in Kodaikanal, Tamil Nadu, India. It's located in the Dindigul district's upper Palani hills, on the site of the old Fort Hamilton. The lake is part of a micro-watershed development project and is created by a dam with sluice outlets</p>
          </Col>

<Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Pine}
                alt="Pine Forest Kodikanal"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Pine Forest</p>
            </div> <br />
            <h6>Pine Forest</h6>
            <p className="package_list_card_p">
              Situated in the southwest of Kodaikanal, Pine Forest is one of the
              most famous tourist spots in the city. It showcases the
              mesmerizing nature around which has been preserved to date which
              is why it is also an important preserved heritage in the town.
              Pine Forests came into existence due to the efforts put in by Mr.
              Bryant as he was the one to state the pine plantations to grow the
              timber in the year 1906
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Pillar}
                alt=" Kodikanal Pillar Rocks"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Pillar Rocks</p>
            </div> <br />
            <h6>Pillar Rocks</h6>
            <p className="package_list_card_p">
              Situated in the 'Princess of Hill stations', Kodaikanal, the
              Pillar Rocks have become a lovely picnic spot. Constituting a
              beautiful mini garden; the place is named so as it has three
              vertically positioned boulders reaching up to a height of 400
              feet. The aura here is full of affection and is evidence of a
              great love story.
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Poombarai}
                alt="Kodaikanal Poombarai Village View"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Poombarai Village </p>
            </div> <br />
            <h6>Poombarai Village</h6>
            <p className="package_list_card_p">
              Poombarai is a tiny village that is famous for its production of
              garlic. It is comfortably nestled among terraced fields and lush
              greenery. It stands at the height of 1,920 m in the Palani Hills
              and offers a picturesque view of the surroundings. You get to see
              colourful houses laid out in neverending greenery.
            </p>
          </Col>

            <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img src={Kurinji} alt="Kurinji Andavar Temple Kodikanal" className='package_list_card_image'  />
              <p className='package_list_card_image_p'>Kurinji Andavar Temple</p>
            </div> <br />
            <h6  >Kurinji Andavar Temple</h6>
            <p className="package_list_card_p">Berijam Lake is a reservoir near Kodaikanal town in Dindigul district of Tamil Nadu, South India. It is at the old site of "Fort Hamilton", in the upper Palani hills. The lake, created by a dam with sluice outlets, is part of a micro–watershed development project.</p>
          </Col>

<Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Mannavanur}
                alt="Kodikanal Mannavanur Lake"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Mannavanur Lake</p>
            </div> <br />
            <h6>Mannavanur Lake</h6>
            <p className="package_list_card_p">
              Mannavanur is a serene farming village in Dindigul District, Tamil
              Nadu - 35km away from Kodaikanal. This quaint village has terrace
              farms that are cut into the hill slopes. It offers nature-lovers a
              chance to experience the farm life, especially spotting rabbits,
              sheep and other animals
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Dolphin}
                alt=" Kodikanal Dolphin's Nose"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Dolphin's Nose</p>
            </div> <br />
            <h6>Dolphin's Nose</h6>
            <p className="package_list_card_p">
              The Dolphin's Nose viewpoint in Kodaikanal is based on a flat,
              protruding piece of rock that is shaped like a dolphin's nose.
              This forms a visible cliff at the height of 6,600 feet, where you
              can experience Kodaikanal at its best - plunging valleys, rugged
              terrain, clear skies, and the splendor of green.
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Vattakanal}
                alt="Kodaikanal Vattakanal Waterfalls"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Vattakanal Waterfalls</p>
            </div> <br />
            <h6>Vattakanal Waterfalls</h6>
            <p className="package_list_card_p">
              Vattakanal near Kodaikanal is a charming little hill station in
              the Western Ghats. At a height of about 2011 m and towards the
              western edge of Tamil Nadu, Vattakanal is a small hamlet at a
              distance of about 7-8 km from Kodaikanal. In Tamil, ‘vatta’
              suggests circle whereas ‘kanal’ translates to the forest
            </p>
          </Col>

          {/* <Col xxl={3} xl={4} lg={6} md={6} sm={12} className='mb-3'>
            <div className="packages_list_card_container">
              <img src={Silent} alt="Silent Valley View Point Kodikanal" className='package_list_card_image'  />
              <p className='package_list_card_image_p'>Silent Valley View Point</p>
            </div>
          </Col> */}

<Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Lake}
                alt="Kodikanal Lake View Point"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Lake View Point</p>
            </div> <br />
            <h6>Lake View Point</h6>
            <p className="package_list_card_p">
              Situated 3.2 km away from the Coakers Walk, Upper Lake View is a
              famous tourist attraction in Kodaikanal and one which is worth
              visiting on the way to Green valley viewpoint. After you reach
              here, you will get an appealing view of the star-shaped lake
              situated here and the surrounding lush greenery which looks
              stunning.
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Shembaganur}
                alt="Shenbaganur Museum of Natural History"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Shenbaganur Museum </p>
            </div> <br />
            <h6>Shenbaganur Museum </h6>
            <p className="package_list_card_p">
              Shenbaganur Museum in Kodaikanal makes up for one of the most
              famous tourist spots and is an exciting place to visit. The museum
              is also one of the best orchidoriums in the country and is located
              5.6 km away from the Kodaikanal Lake. Maintained by the Sacred
              Heart College
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Chettiar}
                alt="Kodaikanal Chettiar Park"
                className="package_list_card_image"
              /> <br />
              <p className="package_list_card_image_p">Chettiar Park</p>
            </div> <br />
            <h6>Chettiar Park</h6>
            <p className="package_list_card_p">
              {" "}
              Covered with lush greenery and exquisite scenic views all around,
              this place is worth spending your time at with your loved ones. A
              wide range of beautiful flowering plants too can be spotted in the
              garden with the main attraction being the Kurinji Flowers which
              bloom once every 12 years
            </p>
          </Col>

          {/* <Col xxl={3} xl={4} lg={6} md={6} sm={12} className='mb-3'>
            <div className="packages_list_card_container">
              <img src={Perumal} alt="Perumal Peak Kodikanal" className='package_list_card_image'  />
              <p className='package_list_card_image_p'>Perumal Peak</p>
            </div>
          </Col> */}

<Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Kukkal}
                alt="Kodikanal Kookal Caves"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Kookal Caves</p>
            </div> <br />
            <h6>Kookal Caves</h6>
            <p className="package_list_card_p">
              Old rock shelters which are believed to be the home to the
              descendants of the Palaiyar tribes who used to wear leaf clothing,
              the Kookal Caves are the overhanging slabs of various types of
              metamorphic rocks known as granulite and charconite. Until the
              mid-1980s, more than 30 families lived on the hilltop where a
              small temple was situated as well.
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Solar}
                alt="Solar Physical Observatory"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">
                Kodaikanal Solar Observatory
              </p>
            </div> <br />
            <h6>Kodaikanal Solar Observatory</h6>
            <p className="package_list_card_p">
              Located at the southern tip of the beautiful Palani Hills, the
              Kodaikanal Solar Observatory was established in 1899 and is owned
              and operated by the Indian Institute of Astrophysics. Its
              longitudinal position makes it a unique site for the study of
              solar behaviour. The scientific abode also houses the most
              extensive collection of solar data, dating way back to the 19th
              century.
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Bear}
                alt="Bear Shola Falls"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">Bear Shola Falls</p>
            </div> <br />
            <h6>Bear Shola Falls</h6>
            <p className="package_list_card_p">
              Located at a mere distance of 2 kilometres from the Kodaikanal
              Lake, the Bear Shola Falls is a popular picnic spot in the region.
              This cascade is a seasonal attraction that comes to life in its
              fullest during the monsoons.
            </p>
          </Col>

          <Col xxl={4} xl={6} lg={6} md={6} sm={12} className="mb-3 p-3">
            <div className="packages_list_card_container">
              <img
                src={Christ}
                alt="Christ the King Church"
                className="package_list_card_image"
              />
              <p className="package_list_card_image_p">
                Christ the King Church
              </p>
            </div> <br />
            <h6>Christ the King Church</h6>
            <p className="package_list_card_p">
              A prominent CSI Church situated in Kodaikanal, Christ the King
              Church has a history of over a hundred years. It is a very sacred
              shrine that is located near the Coakers Walk in Kodaikanal. Built
              by an American Madurai Mission in 1895,
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Service;
