import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Carousel from "react-bootstrap/Carousel";

import Img_1 from "./Images/1 (4).jpeg";
import Img_2 from "./Images/1 (1).jpeg";
import Img_3 from "./Images/1 (2).jpeg";
import './Who.css'

const Who = () => {
  return (
    <div className="who_container">
      <Container fluid>
        <Row className="who_container_2">
          <Col lg={6} md={12} sm={12} xs={12} order={1} className="order-lg-1 order-md-2 order-sm-2 order-xs-2 p-2 ">
            <div className="carousel_container">
              <Carousel data-bs-theme="dark" className="Carousel_container p-3">
                <Carousel.Item interval={1000} touch={true}>
                  <img
                    className="d-block w-100 Carousel_images"
                    src={Img_1}
                    height={450}
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item interval={1000} touch={true}>
                  <img
                     className="d-block w-100 Carousel_images"
                    src={Img_2}
                    height={450}
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item interval={1000} touch={true}>
                  <img
                    className="d-block w-100 Carousel_images"
                    src={Img_3}
                    height={450}
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>

          <Col lg={6} md={12} sm={12} xs={12} order={2} className="order-lg-2 order-md-1 order-sm-1 order-xs-1 p-3">
            <div className="who_we_are_content p-2">
              <h1 className="who_we_are_content_h1">WHO WE ARE</h1>
              <p className="who_we_are_content_p p-2">
                Greetings to you from Kodaikanal Tour Makers. Kodaikanal We are one among
                the leading tour operators in Kodaikanal. 
                Kodaikanal Tour Makers Travels offers you meticulously planned travel arrangements
                throughout your stay at Kodaikanal. And we pick you up from
                different Destinations to kodaikanal according to your choice of
                Vehicles on affordable rates. And also we book Packages, Hotel
                Reservations , Offer Package, One Day Package and Honeymoon
                Package.  Kodaikanal Tour Makers representatives will be at your
                service round the clock. For any further requirement you can
                contact our office professional staff’s to help.
              </p>
              <p className="who_we_are_content_p p-2">
                Among all kodaikanal travels we stand ahead by our unique &
                professional services attractive and cheapest kodaikanal tour
                packages which will be a great budget saver for your dream
                destiny.
              </p>
              <div className="who_we_are_container_btn p-2">
                <button className="who_we_are_btn">See More</button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Who;
