import React from 'react';


const NoPage = () => {
  return (
    <div>
      <center>
        <h1>404 Error Page Not Found</h1>
      </center>
   
    </div>
  )
}

export default NoPage
