import {Route,Routes} from 'react-router-dom'
import RootLayout from './Component/RootLayout';
import Home from './Component/Home';
import About from './Component/About';
import Tour_packages from './Component/Tour_packages';
import Conatct from './Component/Conatct';
import NoPage from './Component/NoPage';
import Our_transport from './Component/our_transport';
import Jeep_safari from './Component/Jeep_safari';
import Hotels from './Component/Hotels';
import Family_Packages from './Component/Family_Packages';
import Tarrif from './Component/Tarrif';


function App() {
  return (
    <div >
<Routes>
  <Route path='/' element={<RootLayout/>}>
  <Route index element={<Home/>}/>
  <Route path='about' element={<About/>}/>
  <Route path='tour_packages' element={<Tour_packages/>}/>
  <Route path='our_transport' element={<Our_transport/>}/>
  <Route path='jeep_safari' element={<Jeep_safari/>}/>
  <Route path='all_packages' element={<Family_Packages/>}/>
  <Route path='tariff' element={<Tarrif/>}/>
  <Route path='hotels' element={<Hotels/>}/>
  <Route path='contact' element={<Conatct/>}/>
  <Route path='*' element={<NoPage/>}/>
  </Route>
</Routes>
    </div>
  );
}

export default App;
