import React from 'react';
import { Container } from '@mui/material';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Carousel from './Carousel/Caurosel';
import Carousel2 from './Carousel 2/Carousel2';
import Carousel_3 from './Carousel 3/Carousel_3';
import Carousel_4 from './carousel 4/Carousel4';

import Rating from '@mui/material/Rating';
import './Place.css';
import { Link } from 'react-router-dom';

const Place = () => {
  const [value, setValue] = React.useState(5);

  return (
    <div className=' place_container_home_page p-1 '>
      <Container className='mb-5'>
        <center>
        <h1 className='important_place_h1 mt-5 mb-2'>Top <span className='attraction_h1'>Attractions </span> in Kodaikanal</h1>
        </center>
        
        <p  className='important_place_p mb-5'>These rankings are informed by traveller reviews—we consider the quality, quantity, recency, consistency of reviews, and the number of page views over time.</p>
        <Row>
        <Col xxl={3} xl={3} lg={3} md={6} sm={12} className='mb-5'>
            <div className="top_place">
              <Carousel  className='top_place_images' />
              <div className="tour_pack">
                
                <p className='package_1'>Package 1</p>
              </div>
              <div className="top_place_description ">
                <center>
                <h5 className='top_place_h5'>Trekking Tour</h5>
                <Rating
                className="rating "
                name="simple-controlled"
                value={value}
              />
              <p className='top_place_p'>Time 9.00 am to 3.00 pm</p>
          
                </center>
           
             
              <div className="list_of_place">
                <ol>
                <li> La Saleth Chruch</li>
                <li>500 Years Old Tree</li>
                <li>Vattakanal Falls</li>
                <li> Pambar Falls</li>
                <li> Line Cave</li>
               <li> Dolphin Nose</li>
                <li> Mountain Beauty</li>
                <li>Echo Point</li>
                <li> Kodai Lake or City Drip</li>
                <br />
                <br />
                <br />
            
                </ol>
              </div>
             
            </div>
            <center>
              <Link to='tariff'>
            <button className='place_know_btn p-3'>Know More</button>
            </Link>
            </center>
            <br />
            </div>

            
          </Col>




          <Col xxl={3} xl={3} lg={3} md={6} sm={12} className='mb-5'>
            <div className="top_place">
              <Carousel2  className='top_place_images' />
              <div className="tour_pack">
                <p className='package_1'>Package 2</p>
              </div>
              <div className="top_place_description ">
                <center>
                <h5 className='top_place_h5'>Wild Ways</h5>
                <Rating
                className="rating "
                name="simple-controlled"
                value={value}
              />
              <p className='top_place_p'>Time 9.00 am to 3.00 pm</p>
          
                </center>
           
             
              <div className="list_of_place">
                <ol>
                <li> Silent Vally View</li>
                <li>Fire Tower</li>
                <li>Berjiam Lake View</li>
                <li> Caps Fly Vally</li>
                <li>Mathikettan Forest View</li>
                <li>  Kodai Lake or City Drip</li>
         <br/>
         <br/>
         <br/>
         <br />
         <br />
         <br />
                </ol>
              </div>
             
            </div>
            <center>
            <Link to='tariff'>
            <button className='place_know_btn p-3'>Know More</button>
            </Link>
            </center>
            <br />
            </div>
          </Col>



          <Col xxl={3} xl={3} lg={3} md={6} sm={12} className='mb-5'>
            <div className="top_place">
              <Carousel_3   className='top_place_images' />
              <div className="tour_pack">
                <p className='package_1'>Package 3</p>
              </div>
              <div className="top_place_description">
                <center>
                <h5 className='top_place_h5'>Village Tour</h5>
                <Rating
                className="rating "
                name="simple-controlled"
                value={value}
              />
              <p className='top_place_p'>Time 9.00 am to 3.00 pm</p>
          
                </center>
           
             
              <div className="list_of_place">
                <ol>
                <li> Observatory Pine Forest</li>
                <li>Rose Garden</li>
                <li>Palani Temple View</li>
                <li> Mahalakshmi Temple</li>
                <li>Poombarai Village View</li>
                <li> Karthi Temple (3000 Years Old)</li>
                <li> Mannavanur Lake View</li>
                <li> Sheep & Rabbit Form</li>
                <li> Mannavanur Lake (Coracle Boating)</li>
             <li> Kodai Lake or City Drip</li>
             <br />
                </ol>
              </div>
             
            </div>
            <center>
            <Link to='tariff'>
            <button className='place_know_btn p-3'>Know More</button>
            </Link>
            </center>
            <br />
            </div>
          </Col>


          <Col xxl={3} xl={3} lg={3} md={6} sm={12} className='mb-5'>
            <div className="top_place">
              <Carousel_4   className='top_place_images' />
              <div className="tour_pack">
                <p className='package_1'>Package 4</p>
              </div>
              <div className="top_place_description  ">
                <center>
                <h5 className='top_place_h5'>Village Tour</h5>
                <Rating
                className="rating "
                name="simple-controlled"
                value={value}
              />
              <p className='top_place_p'>Time 9.00 am to 3.00 pm</p>
          
                </center>
           
             
              <div className="list_of_place">
                <ol>
                <li> Coaker's Walk</li>
                <li>Pine Forest</li>
                <li>Moier Point</li>
                <li>Guna Cave</li>
              
               
                
               <li> Pillar Rocaks</li>
                <li> Golf Course</li>
                <li>  Green Vally View</li>
                <li> Upper Lake</li>
             <li> Shopping Place</li>
             <li> Kodai Lake or City Drip</li>
             <br />
             <br />
                </ol>
              </div>
             
            </div>
            <center>
            <Link to='tariff'>
            <button className='place_know_btn p-3'>Know More</button>
            </Link>
            </center>
            <br />
            </div>
            
          </Col>

      
       
        </Row>
        
      </Container>
    </div>
  );
};

export default Place;
