import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Travel.css";

const Travel = () => {
  return (
    <div className=" travel_banner">
      <Container fluid  className="travel_banner_content_container">
        <Container className="justify-content-center">
          <h1 className=" travel_banner_h1  justify-content-center">
            Travel Agents in Kodaikanal, India
          </h1>
          <p className="travel_banner_p justify-content-center">
            Welcome to Kodaikanal Tour Makers, your premier destination for unforgettable
            travel experiences in Kodaikanal and beyond. As one of the most
            reputed travel agencies in the region, we take pride in offering the
            best deals on hotel bookings, customized holidays, transport
            services, and sightseeing adventures to all the holiday destinations
            around the Kodaikanal.
          </p>
        </Container>
      </Container>
    </div>
  );
};

export default Travel;
