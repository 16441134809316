import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Hotel_1 from "./images/kodaikanal hotels 1 (1).jpg";
import Hotel_2 from "./images/kodaikanal hotels 1 (2).jpg";
import Hotel_3 from "./images/kodaikanal hotels 1 (3).jpg";
import Hotel_4 from "./images/kodaikanal hotels 1 (4).jpg";
import Hotel_5 from "./images/kodaikanal hotels 1 (5).jpg";
import Hotel_6 from "./images/kodaikanal hotels 1 (6).jpg";
import Hotel_7 from "./images/kodaikanal hotels 1 (7).jpg";
import Hotel_8 from "./images/kodaikanal hotels 1 (8).jpg";
import Hotel_9 from "./images/kodaikanal hotels 1 (9).jpg";
import Hotel_10 from "./images/kodaikanal hotels 1 (10).jpg";
import Hotel_11 from "./images/kodaikanal hotels 1 (11).jpg";
import Hotel_12 from "./images/kodaikanal hotels 1 (12).jpg";
import Hotel_13 from "./images/kodaikanal hotels 1 (13).jpg";

import "./Hotels.css";

const Hotels = () => {
  return (
    <div>
      <div className="hotel_banner  mb-5">
        <div className="hotel_banner_h1">HOTEL</div>
      </div>

      {/* first_table */}

      <Container className="mt-5 mb-5">
        <center>
          <h3>Popular Hotels in Kodaikanal</h3>
          <p>
            It's easy to book rooms with us. Just give us a call we will guide
            you through.
          </p>
        </center>
        <Row className="mt-5">
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <tr>
                    <th colSpan={4}>
                      <img
                        src={Hotel_1}
                        alt="kodaikanal hotel"
                        className="hotel_table_img"
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={4}>The Carlton </th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Room Service</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* second Table */}
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <tr>
                    <th colSpan={4}>
                      <img
                        src={Hotel_2}
                        alt="kodaikanal hotel"
                        className="hotel_table_img"
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={4}>
                    Kodai Resort Hotel
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Room Service</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* third_table */}

          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <tr>
                    <th colSpan={4}>
                      <img
                        src={Hotel_3}
                        alt="kodaikanal hotel"
                        className="hotel_table_img"
                      />
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={4}>
                    Sterling Kodai Lake
                    </th>
                 
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Room Service</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* 4th_table */}
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <th colSpan={4}>
                    <img
                      src={Hotel_4}
                      alt="kodaikanal hotel"
                      className="hotel_table_img"
                    />
                  </th>
                  <tr>
                    <th colSpan={4}>Le Poshe by Sparsa</th>
                   
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>24 Hr Laundry + Doctor on call</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* 5th Table */}
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <th colSpan={5}>
                    <img
                      src={Hotel_5}
                      alt="kodaikanal hotel"
                      className="hotel_table_img"
                    />
                  </th>
                  <tr>
                    <th colSpan={4}>
                    Hill Country Resort:
                    </th>
                  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>24 Hr Laundry + Doctor on call</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* 6th_table */}

          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <th colSpan={4}>
                    <img
                      src={Hotel_6}
                      alt="kodaikanal hotel"
                      className="hotel_table_img"
                    />
                  </th>
                  <tr>
                    <th colSpan={4}>
                    JC Residency
                    </th>
                
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>24 Hr Laundry + Doctor on call</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* 7th_table */}
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <th colSpan={4}>
                    <img
                      src={Hotel_7}
                      alt="kodaikanal hotel"
                      className="hotel_table_img"
                    />
                  </th>
                  <tr>
                    <th colSpan={4}>
                    Hotel Kodai International
                    </th>
               
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>24 Hr Laundry + Doctor on call</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* 8th Table */}
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <th colSpan={4}>
                    <img
                      src={Hotel_8}
                      alt="kodaikanal hotel"
                      className="hotel_table_img"
                    />
                  </th>
                  <tr>
                    <th colSpan={4}>Preethi Classic Towers </th>
              
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>24 Hr Laundry + Doctor on call</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* 9th_table */}

          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <th colSpan={4}>
                    <img
                      src={Hotel_9}
                      alt="kodaikanal hotel"
                      className="hotel_table_img"
                    />
                  </th>
                  <tr>
                    <th colSpan={4}>Villa Retreat</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>24 Hr Laundry + Doctor on call</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* 10th_table */}
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <th colSpan={4}>
                    <img
                      src={Hotel_10}
                      alt="kodaikanal hotel"
                      className="hotel_table_img"
                    />
                  </th>
                  <tr>
                    <th colSpan={4}>Hotel Mount View</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>24 Hr Laundry + Doctor on call</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* 11th Table */}
          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <th colSpan={4}>
                    <img
                      src={Hotel_11}
                      alt="kodaikanal hotel"
                      className="hotel_table_img"
                    />
                  </th>
                  <tr>
                    <th colSpan={2}>Great Trails Kodaikanal by GRT Hotels</th>
                    <th colSpan={2}>Star</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>24 Hr Laundry + Doctor on call</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>

          {/* 12th_table */}

          <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
            <div className="hotel_table">
              <Table striped>
                <thead>
                  <th colSpan={4}>
                    <img
                      src={Hotel_12}
                      alt="kodaikanal hotel"
                      className="hotel_table_img"
                    />
                  </th>
                  <tr>
                    <th colSpan={4}>Hotel Cokkers Tower</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={2}>shower products</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Wi-fi</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>24 Hr Laundry + Doctor on call</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Complimentary Breakfast</td>
                    <td colSpan={2}>Yes</td>
                  </tr>

                  <tr>
                    <td colSpan={2}>Restaurant</td>
                    <td colSpan={2}>Yes</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        
      </Container>

<div className="hotel_details_page_container">
<Container  >
{/* second_row */}

        <Row>
          <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
          <div className="find_your_package_container p-5">
            <h1>Did not find your Package?</h1>
            <h1>Feel free to ask us. We‘ll make it for you</h1>
            <p>
              If you plan for a round trip please do view our package service.
              Where you could find a lot of options and taxi service with a
              choice of selecting your cab.
            </p>
            <button className='login_button' style={{marginTop:'10px'}}>For Custome service</button>
          </div>
          
          </Col>
       

          <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
            <div className="find_your_package_image">
                <img src={Hotel_13 } alt="kodaikanal hotel and Tour" style={{width:'100%',height:'400px'}} className="p-5"  />
            </div>
          </Col>
        </Row>


       
      </Container>
</div>
      

     

    </div>
  );
};

export default Hotels;
