import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Side_image from "./images/jeep Kodaikanal.jpg";
import Truck_1 from "./TruckService Images/Shop this Instagram from @provinceofcanada.jpeg";
import Truck_2 from "./TruckService Images/Book a udawalawa safari jeep tour with us we arrnge special udawalawa transit home visit tour free.jpeg";
import Truck_3 from "./TruckService Images/Jeep safari - yala national park.jpeg";
import Truck_4 from "./TruckService Images/download (1).jpeg";

import "./TruckService.css";
import { Link } from "react-router-dom";

const TruckService = () => {
  return (
    <div className="p-2 TruckService_container">
      <Container fluid className=" ">
        <center>
          {" "}
          <h1 className="jeep_h1">Kodaikanal Off-road Adventure Jeep Ride</h1>
        </center>

        <Row>
          <Col className="mb-2" xxl={6} xl={6} lg={6} md={12} sm={12}>
            <img src={Side_image} alt="" className="Side_truck_image p-3" />
          </Col>

          <Col className="" xxl={6} xl={6} lg={6} md={12} sm={12}>
            <p className="jeep_p">
              Welcome to Kodaikanal Jeep Tours, your gateway to experiencing the
              breathtaking beauty and adventure that Kodaikanal has to offer.
              Nestled amidst the lush greenery of the Western Ghats, Kodaikanal
              is renowned for its picturesque landscapes, serene lakes, and
              cascading waterfalls. Our expertly curated jeep tours provide you
              with the perfect opportunity to explore this enchanting hill
              station in all its glory..
            </p>

            <p className="jeep_p">
              Embark on a journey with our knowledgeable and experienced guides
              who are passionate about showcasing the hidden gems of Kodaikanal.
              Whether you're seeking an adrenaline-fueled off-road adventure
              through rugged terrain or a leisurely exploration of the region's
              scenic viewpoints and wildlife sanctuaries, we have the perfect
              itinerary for you.
            </p>

            <p className="jeep_p">
              Our fleet of well-maintained jeeps ensures a comfortable and safe
              ride as you traverse through Kodaikanal's winding roads and forest
              trails. With exclusive access to lesser-known spots and insider
              knowledge about the area's history, culture, and biodiversity, our
              tours offer a truly immersive experience for adventurers of all
              ages.
            </p>
         <Link to='jeep_safari' style={{textDecoration:'none',color:'white'}}><button className="jeep_button p-3">Learn More</button></Link>   
          </Col>
        </Row>


        
       
       
      </Container>
    </div>
  );
};

export default TruckService;
