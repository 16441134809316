import Carousel from 'react-bootstrap/Carousel';
import  Coaker from './Carousel 4 images/Coaker Walk.jpg';
import  Guna_cave from '../Carousel/Caurosel Images/Guna-Caves.jpg';
import Pine from './Carousel 4 images/Pine Forest.webp';
import Moier from './Carousel 4 images/Moier Point.jpg';
import Guna from './Carousel 4 images/Line Cave.jpg';
import Pillar from './Carousel 4 images/Pillar Rocaks.webp';
import Golf from './Carousel 4 images/Golf Course.webp';
import Upper from './Carousel 4 images/Upper Lake.jpg';
import Shopping from './Carousel 4 images/Shopping Place.jpg';





import './Carousel4.css'
 
function DarkVariantExample() {
  return (
    <Carousel >

<Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={ Guna_cave }
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Guna Cave</p>
        </Carousel.Caption>
      </Carousel.Item>

     <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Coaker }
          alt=" kodaikana Coaker's Walk"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '> Coaker's Walk</p>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Pine }
          alt=" kodaikanal Pine Forest "
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Pine Forest</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Moier}
          alt=" kodaikanal Moier Point"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Moier Point</p>
        </Carousel.Caption>
      </Carousel.Item>


      {/* Pambar Falls */}

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={ Guna}
          alt="kodaikanal Guna Cave"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Line Cave
</p>
        </Carousel.Caption>
      </Carousel.Item>

      {/* Line Cave */}

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={ Pillar }
          alt="kodaikanal Pillar Rocks "
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Pillar Rocks</p>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Golf}
          alt="kodaikanal Golf Course"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>  Golf Course</p>
        </Carousel.Caption>
      </Carousel.Item>



      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Upper}
          alt="kodaikanal Upper Lake"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '> Upper Lake</p>
        </Carousel.Caption>
      </Carousel.Item>



      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Shopping}
          alt="kodaikanal Shopping Place"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Shopping Place</p>
        </Carousel.Caption>
      </Carousel.Item>





    </Carousel>




  );
}

export default DarkVariantExample;