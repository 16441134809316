import { Container } from "@mui/material";
import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { IoHappyOutline } from "react-icons/io5";
import { BsBackpack3 } from "react-icons/bs";
import { IoIosPricetags } from "react-icons/io";
import { Ri24HoursFill } from "react-icons/ri";
import { GiCalendarHalfYear } from "react-icons/gi";
import { FaAward } from "react-icons/fa6";
import "./About.css";

const About = () => {
  return (
    <div>
      <div className="container-about-us p-2">
        <center className="choose_us_h1  p-3">
          <h1 className="choose_us_h1_ p-3" >WHY CHOOSE JUDE TRAVELS</h1>
         <hr className="choose_us_hr"/>
        </center>

        <Container>
          <Row>
            <Col xxl={4} xl={4} lg={4} md={6} sm={12} className="mb-3">
           {/* Column for displaying information about  +20,000 Happy Customers */}
              <div className="happy_customer p-3">
                <center>
                  <div className="happy_emoji">
                    <IoHappyOutline className="happy_emoji_icon p-1" />
                  </div>
                </center>
                <center>
                  <h4 className="happy_customer_h4 mt-3 p-1 mb-2">
                    +20,000 Happy Customers
                  </h4>
                  <p className="happy_customer_p  p-1">
                    We take a pride to be a travel agency with more than 20,000
                    happy customers and serving them with awesome trips of
                    various destinations.
                  </p>

                 
                </center>
              </div>
              {/*  +20,000 Happy Customers End */}
            </Col>


            <Col xxl={4} xl={4} lg={4} md={6} sm={12}  className="mb-3">
             {/* Column for displaying information about   +1500 Memorable tours */}
              <div className="happy_customer p-3">
                <center>
                  <div className="happy_emoji">
                    <BsBackpack3 className="happy_emoji_icon p-1" />
                  </div>
                </center>
                <center>
                  <h4 className="happy_customer_h4 mt-3 p-1 mb-2">
                  +1500 Memorable tours
                  </h4>
                  <p className="happy_customer_p  p-1">
                  We are continuously setting bench marks in the travel 
                  industry and now we have completed more than 1500 premium tours.
                  </p>

                 
                </center>
              </div>
              {/*   +1500 Memorable tours End */}
            </Col>

            <Col xxl={4} xl={4} lg={4} md={6} sm={12}  className="mb-3">
           {/* Column for displaying information about   Affordable Price */}
              <div className="happy_customer p-3">
                <center>
                  <div className="happy_emoji">
                    <IoIosPricetags  className="happy_emoji_icon p-1" />
                  </div>
                </center>
                <center>
                  <h4 className="happy_customer_h4 mt-3 p-1 mb-2">
                  Affordable Price
                  </h4>
                  <p className="happy_customer_p  p-1">
                  We serve you with the most affordable packages and 
                  challenge for lowest rates in this travel industry for any destination we serve.
                  </p>

                 
                </center>
              </div>
              {/*   Affordable Price End */}
            </Col>


            <Col xxl={4} xl={4} lg={4} md={6} sm={12}  className="mb-3">
        {/* Column for displaying information about   More about us */}
              <div className="happy_customer p-3">
                <center>
                  <div className="happy_emoji">
                    <Ri24HoursFill  className="happy_emoji_icon p-1" />
                  </div>
                </center>
                <center>
                  <h4 className="happy_customer_h4 mt-3 p-1 mb-2">
                  More about us
                  </h4>
                  <p className="happy_customer_p  p-1">
                  We are at an easy reach, can be said just a click away 
                  from you. For assistance you can send us enquiry via e-mails or calls.
                  </p>

                  
                </center>
              </div>
              {/*   More about us End */}
            </Col>


            <Col xxl={4} xl={4} lg={4} md={6} sm={12}  className="mb-3">
             {/* Column for displaying information about   Our Salient features */}
              <div className="happy_customer p-3">
                <center>
                  <div className="happy_emoji">
                    <GiCalendarHalfYear className="happy_emoji_icon p-1" />
                  </div>
                </center>
                <center>
                  <h4 className="happy_customer_h4 mt-3 p-1 mb-2">
                  Our Salient features
                  </h4>
                  <p className="happy_customer_p  p-1">
                  We have the best team to serve you and ensure 
                  the most affordable packages as well as comfortable tour for our clients.
                  </p>

                
                </center>
              </div>
              {/*   Our Salient features End */}
            </Col>

            <Col xxl={4} xl={4} lg={4} md={6} sm={12}  className="mb-3">
             {/* Column for displaying information about   Awards */}
              <div className="happy_customer p-3">
                <center>
                  <div className="happy_emoji">
                    <FaAward  className="happy_emoji_icon p-1" />
                  </div>
                </center>
                <center>
                  <h4 className="happy_customer_h4 mt-3 p-1 mb-2">
                  Awards
                  </h4>
                  <p className="happy_customer_p  p-1">
                  We have been awarded as one of the best travel 
                  agency in India for serving a huge number of happy clients with best services.
                  </p>

                 
                </center>
              </div>
              {/*   Awards End */}
            </Col>

           
       
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default About;
