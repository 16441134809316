import React from "react";
import Video from "./Videos/152189 (Original).mp4";
import png from "./Banner Images/slider-1-img-1 (1).png";
import { motion } from "framer-motion";
import "./Banner.css";
import { Container } from "@mui/material";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

const Banner = () => {
  return (
    <div className="home">
      <video src={Video} autoPlay loop muted className="video"></video>
      <div className="content_section">
        <motion.p
          initial={{ x: -150 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 1 }}
          className="content_section_gif_content"
        >
          Adventurous
        </motion.p>

        <motion.img
          src={png}
          alt=""
          className="content_section_img"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
        <Container fluid>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12}>
              <center>
              <h1 className="content_section_h1">Your Journey begins</h1>
              <p className="content_section_p">
                Embark on a voyage through lush valleys, cascading waterfalls,
                and captivating vistas, where every step is a new adventure.
              </p>
              </center>
             
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );
};

export default Banner;
