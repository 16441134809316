import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaLocationDot } from "react-icons/fa6";
import { MdTravelExplore } from "react-icons/md";
import { MdOutlineFilterFrames } from "react-icons/md";
import Group from "./images/ooty-group-tour-package-for-2-days (1).webp";
import Paradise from "./Family_packages_images/Best Resorts In Munnar Closer To Nature.jpeg";
import Best_price from "./images/best Price.png";
import Family from "./images/Family Package.jpg";
import Honey from "./images/Honeymoon-small-1.webp";
import Friends from "./images/Family-small-1 (1).webp";
import Accordion from "react-bootstrap/Accordion";
import Image_1 from "./Family_packages_images/Kodaikanal.jpeg";
import Faq from "./Family_packages_images/faq.webp";
import { FaHotel } from "react-icons/fa6";
import { FaCableCar } from "react-icons/fa6";
import { FaHorse } from "react-icons/fa";
import { GiKnifeFork } from "react-icons/gi";
import { IoIosBoat } from "react-icons/io";
import { IoGiftSharp } from "react-icons/io5";
import { FaParachuteBox } from "react-icons/fa";
import { LiaFileContractSolid } from "react-icons/lia";
import { FaUserFriends } from "react-icons/fa";
import { FaGlassMartiniAlt } from "react-icons/fa";
import "./Family_Packages.css";

const Family_Packages = () => {
  return (
    <div>
      <div className="family_package_banner">
        <h1 className="family_package_banner_h1 p-2">Tour Package</h1>
        <div className="card_for_family_banner"></div>
      </div>
      <Container className="p-2 mb-5 mt-5">
        <Row>
          <Col xxl={6} xs={12} lg={12} md={12} sm={12} className="">
            <h1>Tour Package in Kodaikanal for Unforgettable Experience</h1>
            <p className="tour_para">
              Are you ready to embark on a journey to the picturesque hill
              station of Kodaikanal, nestled in the lap of the Nilgiri
              Mountains? Embark on an unforgettable family adventure with our
              Kodaikanal Family Tour Packages, meticulously crafted to cater to
              the diverse interests of every family member.
            </p>
            <p className="tour_para">
              Our thoughtfully curated packages include guided tours to iconic
              attractions such as the Botanical Gardens, Kodaikanal Lake, and
              Doddabetta Peak, providing panoramic views of the surrounding
              landscapes.
            </p>
            <Row>
              <h3 className="mt-3 mb-3 f14_years">14+ Years Of Experience</h3>
              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="">
                <p className="tour_para">
                  <FaHotel className="package_icons_family" /> Accommodation
                </p>
                <p className="tour_para">
                  <FaCableCar className="package_icons_family" /> Sightseeing
                  Cab
                </p>
                <p className="tour_para">
                  <FaHorse className="package_icons_family" /> Horse Riding
                </p>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} className="">
                <p className="tour_para">
                  <GiKnifeFork className="package_icons_family" /> Food
                </p>
                <p className="tour_para">
                  <IoIosBoat className="package_icons_family" /> Boating
                </p>
                <p className="tour_para">
                  <IoGiftSharp className="package_icons_family" /> Surprise
                  Gifts
                </p>
              </Col>
            </Row>
          </Col>
          <Col xxl={6} xs={12} lg={12} md={12} sm={12}>
            <div className="image_of_famaily_package">
              <img
                src={Image_1}
                alt="Kodaikanal family package"
                className="kodaikanal_Family_package "
                height={500}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="Our_Advantages">
        <div className="our_advantages_div">
          <Row className="p-3">
            <Col xxl={6} xl={6} lg={12} md={12} sm={12} className="">
              <p className="">Our Advantages</p>
              <h3>Why Should Choose Our Tour Packages?</h3>
              <p>
                Choose our 5 days family tour packages for a stress-free,
                immersive, and enriching travel experience.
              </p>
              <ol>
                <li>
                  <strong style={{ color: "white" }}>
                    Tailored Experiences:{" "}
                  </strong>
                  Our tour packages are meticulously designed to cater to the
                  diverse interests and preferences of every family member.
                </li>
                <li>
                  <strong>Expert Guidance: </strong>With seasoned guides and
                  local experts, you'll gain unique insights into the
                  destinations, ensuring an enriching journey.
                </li>
                <li>
                  <strong>Seamless Planning: </strong> Leave the hassle of
                  planning to us. From accommodation to activities, we take care
                  of every detail, so you can focus on creating memories.
                </li>
                <li>
                  <strong>Safety Assurance: </strong>Your safety is our top
                  priority. We adhere to the highest safety standards and
                  protocols to ensure a worry-free vacation.
                </li>
                <li>
                  <strong>Value for Money: </strong>Get the best value for your
                  investment with our competitive pricing and inclusive packages
                  that cover all essential aspects of your trip.
                </li>
                <li>
                  <strong>Flexible Itineraries: </strong>Enjoy the flexibility
                  to customize your itinerary according to your preferences,
                  ensuring a truly personalized experience.
                </li>
               
              </ol>
            </Col>

            <Col xxl={6} xl={6} lg={12} md={12} sm={12} className="">
              <img
                src={Paradise}
                alt="kodaikanal Family package"
                className="should_choose_image p-2"
              />
            </Col>
          </Row>
        </div>
        <br />
        <Row>
          <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
            <div className="advantages_card">
              <center>
                <img src={Best_price} alt="kodaikanal family Tour" />
                <h3>Safety First Always</h3>
                <p>
                  Your peace of mind is our priority; we prioritize the safety
                  and well-being.
                </p>
              </center>
            </div>
          </Col>

          <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
            <div className="advantages_card">
              <center>
                <img
                  src={Best_price}
                  alt="kodaikanal family Tour"
                  className=""
                />
                <h3>Best Price Guarantee</h3>
                <p>
                  Enjoy a cost-effective way to explore multiple sightseeing
                  spots with inclusive amenities.
                </p>
              </center>
            </div>
          </Col>

          <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
            <div className="advantages_card">
              <center>
                <img src={Best_price} alt="kodaikanal family Tour" />
                <h3>Quick & Easy Bookings</h3>
                <p>
                  We ensure a hassle-free and efficient booking process,
                  allowing you to secure your travel plans.
                </p>
              </center>
            </div>
          </Col>

          <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
            <div className="advantages_card">
              <center>
                <img src={Best_price} alt="kodaikanal family Tour" />
                <h3>Custom Sightseeing Visit</h3>
                <p>
                  We tailor itineraries to meet your specific requirements and
                  ensure your complete satisfaction.
                </p>
              </center>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="all_three_packages_container p-2">
        <Container className="mt-5 mb-5  ">
          <Row>
            <center className=" ">
              <h1 className="mb-3">Kodaikanal Tour Packages</h1>
              <p className="all_three_packages_top_para mb-5">
                Your need is our service. Just pick your tour package from higly
                rated Kodaikanal tour packages. We deals with Kodaikanal
                Honeymoon Packages, Kodaikanal Family Packages etc. Just click
                the packages below for more details
              </p>
            </center>

            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={12}
              sm={12}
              className="honey_moon_package_box_col p-2"
            >
              <div className="honey_moon_package_box">
                <img
                  src={Friends}
                  alt="kodaikanal Family Packages"
                  className="honey_moon_package_box_img"
                />
              </div>
              <div className="honey_moon_package_box_description">
                <h4>Family Tour Package</h4>
                <p>Package Starts From</p>
                <button className="honey_moon_package_box_btn p-3">
                  Family Tour Package
                </button>
              </div>
            </Col>

            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={12}
              sm={12}
              className="honey_moon_package_box_col p-2"
            >
              <div className="honey_moon_package_box">
                <img
                  src={Honey}
                  alt="kodaikanal Family Packages"
                  className="honey_moon_package_box_img"
                />
              </div>
              <div className="honey_moon_package_box_description">
                <h4>Honey Moon Package</h4>
                <p>Package Starts From</p>
                <button className="honey_moon_package_box_btn p-3">
                  Honey Moon Package
                </button>
              </div>
            </Col>

            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={12}
              sm={12}
              className="honey_moon_package_box_col p-2"
            >
              <div className="honey_moon_package_box">
                <img
                  src={Family}
                  alt="kodaikanal Family Packages"
                  className="honey_moon_package_box_img"
                />
              </div>
              <div className="honey_moon_package_box_description">
                <h4>Friends Tour Package</h4>
                <p>Package Starts From</p>
                <button className="honey_moon_package_box_btn p-3 ">
                  Friends Tour Package
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="four_packages_tour ">
        <Row>
          <Col xxl={6} XL={6} lg={6} md={6} sm={12} className="mb-4 mt-4">
            <h3>
              {" "}
              <FaParachuteBox className="tour_para" /> Honeymoon Packages
            </h3>
            <p className="tour_para" style={{ marginLeft: "35px" }}>
              We are there for making your honeymoon more romantic and memorable
              trip in Ooty. We also provide out station pickup and drop...
            </p>
          </Col>

          <Col
            xxl={6}
            XL={6}
            lg={6}
            md={6}
            sm={12}
            className="mb-4 mt-4 orange_container"
          >
            <h3>
              <LiaFileContractSolid className="tour_para" /> Family Package
            </h3>
            <p className="tour_para" style={{ marginLeft: "35px" }}>
              Are you planning for a trip to Ooty with your family? You don’t
              know about Ooty? Don’t get nervous, we are there to serve you.
              Book your packages with us and step in Ooty with your plan...
            </p>
          </Col>

          <Col xxl={6} XL={6} lg={6} md={6} sm={12} className="mb-4 mt-4">
            <h3>
              <FaUserFriends className="tour_para" /> Friends Package
            </h3>
            <p className="tour_para" style={{ marginLeft: "35px" }}>
              Find best Quote to visit for with friends. Explore latest with
              friends trip ideasand get best deals on with friends packages.
              Also find activities like trekking,hiking...
            </p>
          </Col>

          <Col xxl={6} XL={6} lg={6} md={6} sm={12} className="mb-4 mt-4">
            <h3>
              <FaGlassMartiniAlt className="tour_para" /> Group Packages
            </h3>
            <p className="tour_para" style={{ marginLeft: "35px" }}>
              Feeling bored with work and studies? Need Stress Relief ? Then
              explore to Ooty. We offer best deals for corporate and educational
              tours...
            </p>
            <div></div>
          </Col>

          <Col xxl={6} XL={6} lg={6} md={6} sm={12}></Col>
        </Row>
      </Container>
      {/* Faq */}
      <Container className="mb-5 mt-5">
        <Row>
          <center>
            <h3>FAQ</h3>
            <h1>Discover Customer’s Frequent Asked Questions</h1>
          </center>

          <Col xxl={6} xl={6} lg={6} md={12} sm={12} className="p-2 mt-4">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What's included in the Kodaikanal Family Tour Packages?
                </Accordion.Header>
                <Accordion.Body>
                  Our package includes family-friendly accommodation, meals
                  (typically breakfast), transportation, and guided tours as
                  outlined in the detailed itinerary.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div>
              <br />
            </div>
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Can I customize the itinerary?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, we offer customization options to tailor the itinerary
                  based on your family’s interests. Contact our customer service
                  for personalized arrangements.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div>
              <br />
            </div>
            <Accordion>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Is transportation included in the package?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, our packages usually include transportation from the
                  designated arrival points, such as airports or bus stations,
                  to your accommodation in Ooty (Extra charges are applied), as
                  well as local transportation for sightseeing as per the
                  itinerary. Rent it from.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div>
              <br />
            </div>
            <Accordion>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What are your payment terms?
                </Accordion.Header>
                <Accordion.Body>
                  You can pay us in advance through Bank remittance HDFC and
                  Kotak Mahindra Bank. In certain cases, we may ask you to pay
                  us in advance as a condition. In due course of time, we shall
                  also be accepting secured online credit card payments.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div>
              <br />
            </div>
            <Accordion>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Is north Indian Food Available In Kodaikanal?
                </Accordion.Header>
                <Accordion.Body>
                  As a popular hill station drawing visitors from all corners of
                  India, Ooty boasts a rich culinary scene, featuring a variety
                  of Indian dishes to satisfy the tastes of every traveler.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div>
              <br />
            </div>
            <Accordion>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  What will happen if I have to change my tour program during
                  the trip?
                </Accordion.Header>
                <Accordion.Body>
                  This is possible, and in case of a longer duration, we shall
                  work out additional charges and let you know at the time when
                  you make changes and in cases of a lesser number of days, we
                  shall let you know the refund amount due to you which will be
                  paid to you. Booking.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <br />
            <Accordion>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Is there any specific age range for child pricing?
                </Accordion.Header>
                <Accordion.Body>
                  Children under 7 years have reduced or no charges. For above
                  seven years, we charge Rs. 1000 per child.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>

          <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
            <img src={Faq} alt="" srcset="" className="faq_images p-2" />
          </Col>
        </Row>
      </Container>
      ;
    </div>
  );
};

export default Family_Packages;
