import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GiJeep } from "react-icons/gi";
import { PiJeepDuotone } from "react-icons/pi";
import { LuTent } from "react-icons/lu";
import { MdContactSupport } from "react-icons/md";
import { GrCertificate } from "react-icons/gr";
import { Ri24HoursFill } from "react-icons/ri";

import Service_img_1 from "./Jeep_safari Images/kodaikanal Mountain Jeep Safari.jpeg";
import Service_img_2 from "./Jeep_safari Images/2.jpg";
import Service_img_3 from "./Jeep_safari Images/3.jpg";
import "./Jeep_safari.css";

const Jeep_safari = () => {
  return (
    <div>
      <div className="jeep_safari_conatiner">
        <h1 className="jeep_safari_h1">Kodaikanal Rider Jeep Safari</h1>
      </div>
      <Container>
        <Row
          className="justify-content-center mb-5 mt-5 p-2"
          style={{ gap: "30px" }}
        >
          {/* <h1 className="jeep_page_h1">OUR SERVICES</h1> */}
          <p className="off_road_safari_p">
            Jude Travels provides comprehensive services for the leisure and
            small-business traveler, backed by real-time booking ability. The
            website offers Jeep Safari bookings and holidays!!!
          </p>
       

          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={12}
            sm={12}
            xm={12}
            xs={12}
            className=" p-2"
          >
            <div className="jeep_service_container ">
              <img
                src={Service_img_1}
                alt="kodaikanal jeep service"
                className="Service_img_1 mb-2"
              />
              <h5 className="p-1">Jeep safari(Pallangi Kombal OFF-Road)</h5>
              <center>
              <h5>Tour 1</h5>
              </center>
          <ol>
            <li>Vilpatti Village Farm View</li>
            <li>Pallangi WaterFall View</li>
            <li>Vattapparai Valley View (film shoting Point )</li>
            <li>Palani View</li>
            <li>River Waterfall 1 & 2</li>
          </ol>
              <p className="p-1 off_road_safari_p">
              Kodaikanal Tour Makers provides comprehensive services for the leisure and
            small-business traveler, backed by real-time booking ability. The
            website offers Jeep Safari bookings and holidays!!!
               
              </p>
             
            <br />
            </div>
          </Col>

          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={12}
            sm={12}
            xm={12}
            xs={12}
            className=" p-2"
          >
            <div className="jeep_service_container">
              <img
                src={Service_img_3}
                alt="kodaikanal jeep service"
                className="Service_img_1 mb-2"
              />
              <h5 className="p-1">Jeep safari(Anji Veedu to Pulliyur Off Road)</h5>
              <center>
              <h5>Tour 2</h5>
              </center>
          <ol>
            <li>Shenbaganur Museum of Natural History</li>
            <li>The House of Primitive Man</li>
            <li>Anju veedu River</li>
            <li>Anju veedu WaterFall</li>
            <li>Elephant Valley View</li>
            <li>360 Degree Mountain View</li>
          </ol>

              <p className="off_road_safari_p p-1">
              Kodaikanal Tour Makers  provides comprehensive services for the leisure and
            small-business traveler, backed by real-time booking ability. The
            website offers Jeep Safari bookings and holidays!!!
              </p>
           
            </div>
          </Col>

          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={12}
            sm={12}
            xm={12}
            xs={12}
            className=" p-2"
          >
            <div className="jeep_service_container">
              <img
                src={Service_img_2}
                alt="kodaikanal jeep service"
                className="Service_img_1 mb-2"
              />
              <h5 className="p-1">Jeep safari(Pazhamputhur off Road)</h5>
              <center>
              <h5>Tour 3</h5>
              </center>
          <ol>
            <li>Kookal Lake</li>
            <li>Kookal Village & Valley View</li>
            <li>Pazhamputhur Valley View</li>
           
          </ol>
    
       
              <p className="off_road_safari_p p-1">
              Kodaikanal Tour Makers provides comprehensive services for the leisure and
            small-business traveler, backed by real-time booking ability. The
            website offers Jeep Safari bookings and holidays!!!
              </p>
             <br />
             <br />
             <br />
             <br />
            </div>
          </Col>
        </Row>

        <center className="mt-5 mb-5">
         
        

          <p className="off_road_safari_p">
            A four wheel drive through the hill roads has become one of the most
            adventurous entertainment in kodaikanal. we provide with our tour
            packages an opportunity to discover the deep mountain paths to reach
            your destinations in the paradise through a well equipped jeep
            service. Guiding through your tours this jeep safari would take you
            down the road of adventure to absorb every inch of natural beauty of
            kodaikanal.
          </p>
        </center>

        <Row
          style={{ gap: "60px" }}
          className="justify-content-center mb-5 mt-5 p-2"
        >
          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xm={12}
            xs={12}
            className="off_road_container p-2"
          >
            <center>
              <GiJeep className="jeep_icon" />

              <h6>OFF ROAD SAFARI</h6>
              <p className="off_road_safari_p p-1">
                Join us for an unforgettable off-road safari experience,
                navigating through rugged terrain and encountering nature's
                wonders up close. Our expert guides ensure a secure journey as
                we traverse diverse landscapes, from rocky mountainsides to lush
                forests.
              </p>
            </center>
          </Col>

          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xm={12}
            xs={12}
            className="off_road_container p-2"
          >
            <center >
              <PiJeepDuotone className="jeep_icon p-1" />

              <h6>NIGHT SAFARI</h6>
              <p className="off_road_safari_p ">
                Experience the thrill of the night as you journey through
                shadowy forests and moonlit plains, encountering fascinating
                nocturnal creatures along the way. Led by expert guides, immerse
                yourself in the symphony of the jungle, alive with the calls of
                owls and rustling of nocturnal animals.
              </p>
            </center>
          </Col>

          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xm={12}
            xs={12}
            className="off_road_container p-2"
          >
            <center>
              <LuTent className="jeep_icon p-1" />

              <h6>WILD STAYING</h6>
              <p className="off_road_safari_p">
                Escape the ordinary and immerse yourself in the heart of the
                wilderness with our unique accommodations. From luxury tents
                under the stars to cozy cabins tucked away in the forest, each
                stay promises an unforgettable connection with nature.
              </p>
            </center>
          </Col>
        </Row>

        <Row
          style={{ gap: "60px" }}
          className="justify-content-center mb-5 mt-5 p-2"
        >
          <center>
            <h1 className="jeep_page_h1 mb-4 mt-4">WELCOME TO Kodaikanal RIDER !!!</h1>
            <p className="off_road_safari_p">
              we provide with our tour packages an opportunity to discover the
              deep mountain paths to reach your destinations in the paradise
              through a well equipped jeep service. Guiding through your tours
              this jeep safari would take you down the road of adventure to
              absorb every inch of natural beauty of Kodaikanal.!
            </p>
          </center>
          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xm={12}
            xs={12}
            className="off_road_container p-2"
          >
            <center>
              <MdContactSupport className="jeep_icon p-1" />

              <h6>GUIDE SUPPORT</h6>
              <p className="off_road_safari_p">
              Kodaikanal Tour Makers Rider Jeep safari is kodaikanal's leading online travel
                company and among the most successful Internet companies in
                Kodaikanal.
              </p>
            </center>
          </Col>

          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xm={12}
            xs={12}
            className="off_road_container p-2"
          >
            <center>
              <GrCertificate className="jeep_icon p-1" />

              <h6>WE ARE CERTIFIED</h6>
              <p className="off_road_safari_p">
                Beautiful place, fast support. I really couldn’t wish for more.
                Really great job guys! Looking for more cool Tour from you.
              </p>
            </center>
          </Col>

          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xm={12}
            xs={12}
            className="off_road_container p-2"
          >
            <center>
              <Ri24HoursFill className="jeep_icon p-1" />

              <h6>24/7 SUPPORT</h6>
              <p className="off_road_safari_p">
                Our professional team is here for you 24/7, so please don't
                hesitate to contact us if you have any questions please call us
              </p>
            </center>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Jeep_safari;
