import Carousel from 'react-bootstrap/Carousel';
import Silent from './Carousel 2 images/Silent Vally View.webp';
import Fire  from './Carousel 2 images/Fire Tower.jpg';

import Berjiam from './Carousel 2 images/Berjiam Lake View.webp';
import Caps from './Carousel 2 images/Caps Fly Vally.jpg';
import Mathikettan from './Carousel 2 images/Mathikettan Forest View.png';
import Kodai from './Carousel 2 images/Kodai Lake or City Drip.jpg';


 
function DarkVariantExample() {
  return (
    <Carousel >
      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Silent}
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Silent Vally</p>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Fire }
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '> Fire Tower</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Berjiam}
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Berjiam Lake</p>
        </Carousel.Caption>
      </Carousel.Item>


      {/* Pambar Falls */}

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={ Caps}
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '> Caps Fly Vally</p>
        </Carousel.Caption>
      </Carousel.Item>

      {/* Line Cave */}

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={ Mathikettan }
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Mathikettan Forest</p>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Kodai}
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '> City Drip</p>
        </Carousel.Caption>
      </Carousel.Item>




    </Carousel>




  );
}

export default DarkVariantExample;