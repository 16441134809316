import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, Outlet } from 'react-router-dom';
import Banner_header from '../Home/Banner Header/Banner_header';
import Logo from './images/jude travels logo.png';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaPhoneAlt } from 'react-icons/fa';
import Footer from '../Component/Footer';
import './RootLayout.css';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function ColorSchemesExample() {
  return (
    <div>
      <ScrollToTop />
      <Banner_header />
      <Navbar expand="lg" className="navbar_container justify-content-between">
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt=""
              src={Logo}
              width="160"
              height="80"
              className="d-inline-block align-top"
            />{' '}
            {/* React Bootstrap */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end py-2  ">
            <Nav className="mr-auto">
              <Nav.Link as={NavLink} to="/" className="nav_details nav_details_content  px-2">
                Home
              </Nav.Link>
              <Nav.Link as={NavLink} to="about" className="nav_details nav_details_content  px-2">
                About
              </Nav.Link>
              <Nav.Link as={NavLink} to="tour_packages" className="nav_details nav_details_content  px-2">
                Top Places
              </Nav.Link>
              <Nav.Link as={NavLink} to="jeep_safari" className="nav_details nav_details_content  px-2">
                Jeep Safari
              </Nav.Link>
              <Nav.Link as={NavLink} to="our_transport" className="nav_details nav_details_content  px-2">
                Our Transport
              </Nav.Link>
              <Nav.Link as={NavLink} to="all_packages" className="nav_details nav_details_content  px-2">
                Package
              </Nav.Link>
              <Nav.Link as={NavLink} to="tariff" className="nav_details nav_details_content  px-2">
                Tariff
              </Nav.Link>
              <Nav.Link as={NavLink} to="hotels" className="nav_details nav_details_content  px-2">
                Hotels
              </Nav.Link>
              <Nav.Link as={NavLink} to="contact" className="nav_details nav_details_content px-2">
                Contact
              </Nav.Link>
            </Nav>
            <Nav className='px-4'>
            <a href="tel:+919363947383">
            <button className="login_button " style={{ marginTop: '15px' }}>
                <FaPhoneAlt /> +91 9363947383
              </button>
</a>
              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default ColorSchemesExample;
