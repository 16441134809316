import Carousel from 'react-bootstrap/Carousel';
import Church from './Caurosel Images/la-saleth-church.jpg';
import Tree  from './Caurosel Images/500-years-old-tree.jpg';
import Vattakanal from './Caurosel Images/photo3jpg.jpg';
import Pambar from './Caurosel Images/Pambar Falls.jpg';

import  Guna from './Caurosel Images/Guna-Caves.jpg';
import  Dolphin from './Caurosel Images/Vattakanal-Dolphin-Nose-Point-Kodaikanal.jpg';
import './Caurosel.css'
 
function DarkVariantExample() {
  return (
    <Carousel >
     <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Church}
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p p-1'>la-saleth-church</p>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Tree }
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '> Old Tree</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Vattakanal}
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Vattakanal Falls</p>
        </Carousel.Caption>
      </Carousel.Item>


      {/* Pambar Falls */}

      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Pambar}
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p '>Pambar Falls</p>
        </Carousel.Caption>
      </Carousel.Item>

      {/* Line Cave */}

     


      <Carousel.Item interval={1500}>
        <img
          className="d-block w-100"
          src={Dolphin}
          alt="First slide"
          height={210}
        />
        <Carousel.Caption>
          <br />
          <br />
          <p className='carosel_p p-1'>Dolphin Nose</p>
        </Carousel.Caption>
      </Carousel.Item>




    </Carousel>




  );
}

export default DarkVariantExample;